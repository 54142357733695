import { FormProvider } from '@cooltra/form';

import { usePreferredServicePoints } from '~/libs/preferred-service-points';

import { VehiclesFiltersFormValues } from './VehiclesFilters/vehicles-filter-form';
import { VehiclesList } from './VehiclesList';

export const Vehicles = () => {
  const { preferredServicePoints } = usePreferredServicePoints();

  return (
    <FormProvider<VehiclesFiltersFormValues>
      initialValues={{ servicePointId: preferredServicePoints }}
    >
      <VehiclesList />
    </FormProvider>
  );
};
