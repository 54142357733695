import {
  useContractPaymentsQuery,
  useContractQuery,
} from '@cooltra/station-based-api';
import { Button, CopyButton } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import ReactQrCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { HTMLAttributes } from 'react';
import { classNames } from '@cooltra/utils';

import { getEnvVariable } from '~/utils/environment';

import messages from './messages';

export type QRPaymentProps = HTMLAttributes<HTMLDivElement> & {
  ticketId: string;
};

export const QRPayment = ({ ticketId, className, ...rest }: QRPaymentProps) => {
  const { contractId = '' } = useParams();

  const { data: contract } = useContractQuery(contractId, {
    enabled: false,
  });
  const { refetch, isRefetching } = useContractPaymentsQuery(contractId, {
    enabled: false,
  });

  const onVerifyPaymentPaid = () => refetch();

  const language = contract?.user?.language.toLowerCase() || 'en';
  const publicQRLink = `${getEnvVariable('COOLTRA_WEB_DOMAIN')}/${language}/contracts/${contractId}/pay-ticket/${ticketId}`;

  return (
    <div className={classNames('text-center', className)} {...rest}>
      <h2 className="text-xl">
        <FormattedMessage {...messages.stripeQRPaymentMethod} />
      </h2>
      <div className="pt-16" data-testid="STRIPE_QR">
        <ReactQrCode
          size={382}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={publicQRLink}
          viewBox="0 0 256 256"
        />
      </div>
      <div>
        <CopyButton
          className="text-primary-500 font-semibold py-9"
          value={publicQRLink}
          label={
            <span className="text-primary-500 font-semibold pl-1">
              <FormattedMessage {...messages.copyLink} />
            </span>
          }
        />
      </div>
      <Button onClick={onVerifyPaymentPaid} loading={isRefetching}>
        <FormattedMessage {...messages.verifyIfPaid} />
      </Button>
    </div>
  );
};
