import { useField, useFormContext } from '@cooltra/form';
import { InputError, InputLabel, SelectField } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export type ServicePointFormValues = {
  pickup: string;
  dropOffDate: string;
  dropOffTime: string;
  pickUpDate: string;
  pickUpTime: string;
};

export type DeliveryServicePointProps = {
  shouldReset?: boolean;
  disabled?: boolean;
};

const name = 'pickup';

export const DeliveryServicePoint = ({
  shouldReset = false,
  disabled = false,
}: DeliveryServicePointProps) => {
  const { isSubmitting, setValues, values, isFormDisabled } =
    useFormContext<ServicePointFormValues>();

  const { value, error, touched, setTouched } = useField(name);
  const handleChange = (val: string) => {
    if (shouldReset) {
      return setValues({
        ...values,
        pickup: val,
      });
    }
    setValues({
      ...values,
      pickup: val,
      dropOffDate: undefined,
      dropOffTime: '',
      pickUpDate: undefined,
      pickUpTime: '',
    });
  };

  const { servicePointsOptions, isLoading } = useServicePointOptions();

  return (
    <SelectField
      id="delivery"
      key={`${name}-${value}`}
      error={touched && error ? <InputError>{error}</InputError> : undefined}
      onChange={handleChange}
      value={value}
      onBlur={() => setTouched(true)}
      name={name}
      disabled={isFormDisabled || isSubmitting || isLoading || disabled}
      options={servicePointsOptions}
      label={
        <InputLabel htmlFor="delivery">
          <FormattedMessage {...messages.servicePoint} />
        </InputLabel>
      }
    />
  );
};
