import { RouterBaseNavLink, RouterBaseNavLinkProps } from '@cooltra/navigation';
import { Tooltip } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  MdAddCircleOutline,
  MdEuroSymbol,
  MdLibraryAddCheck,
  MdListAlt,
  MdOutlineCalendarMonth,
  MdPersonOutline,
} from 'react-icons/md';
import { IconType } from 'react-icons';

import { CustomIcon } from '../../CustomIcon';

import messages from './messages';

export type ContractSidebarLinkName =
  | 'overview'
  | 'details'
  | 'driver'
  | 'vehicles'
  | 'extras'
  | 'payments'
  | 'checks-damages'
  | 'click-and-ride';

type ContractSidebarLinkItem = {
  to: string;
  label: MessageDescriptor | string;
  Icon: IconType;
};

const contractSidebarItems: Record<
  ContractSidebarLinkName,
  ContractSidebarLinkItem
> = {
  overview: {
    to: 'overview',
    label: messages.overview,
    Icon: MdListAlt,
  },
  details: {
    to: 'details',
    label: messages.details,
    Icon: MdOutlineCalendarMonth,
  },
  driver: {
    to: 'driver',
    label: messages.driver,
    Icon: MdPersonOutline,
  },
  vehicles: {
    to: 'vehicles',
    label: messages.vehicle,
    Icon: CustomIcon.Motorcycle,
  },
  extras: {
    to: 'extras',
    label: messages.extras,
    Icon: MdAddCircleOutline,
  },
  payments: {
    to: 'payments',
    label: messages.payment,
    Icon: MdEuroSymbol,
  },
  'checks-damages': {
    to: 'checks-damages',
    label: messages.checksDamages,
    Icon: MdLibraryAddCheck,
  },
  'click-and-ride': {
    to: 'click-and-ride',
    label: 'Click & Ride',
    Icon: CustomIcon.ClickAndRide,
  },
};

export type ContractSidebarLinkProps = Omit<
  RouterBaseNavLinkProps,
  'children' | 'to'
> & {
  disabled?: boolean;
  warning?: boolean;
  name: ContractSidebarLinkName;
};

export const ContractSidebarLink = ({
  name,
  warning = false,
  disabled = false,
  ...rest
}: ContractSidebarLinkProps) => {
  const { formatMessage } = useIntl();
  const { to, label, Icon } = contractSidebarItems[name];
  const ariaLabel = typeof label === 'string' ? label : formatMessage(label);

  return disabled ? (
    <button
      className="w-16 h-16 flex items-center justify-center text-neutral-100"
      disabled={true}
      aria-label={ariaLabel}
    >
      <Icon className="text-2xl" />
    </button>
  ) : (
    <Tooltip
      tooltipClassName="bg-neutral-600 text-neutral-0"
      popperOptions={{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['right'],
            },
          },
        ],
      }}
      button={
        <RouterBaseNavLink
          aria-label={ariaLabel}
          to={to}
          className={({ isActive }) =>
            classNames(
              'w-16 h-16 flex items-center justify-center transition-all',
              'focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-200',
              'border-r-[6px] border-l-[6px] border-solid',
              isActive
                ? 'text-primary-500 border-l-primary-500'
                : 'text-neutral-400 border-l-transparent'
            )
          }
          {...rest}
        >
          <Icon className="text-2xl" />
          {!disabled && warning && (
            <div
              className="absolute right-2 top-2 bg-warning-500 rounded-full w-2.5 h-2.5"
              data-testid="SIDEBARLINK_WARNING"
            />
          )}
        </RouterBaseNavLink>
      }
      content={
        <div className="px-5 py-3 relative">
          <span className="text-neutral-0 font-semibold">{ariaLabel}</span>
        </div>
      }
    />
  );
};
