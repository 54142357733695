import { Icon } from '@cooltra/ui';
import { MdCheckCircle } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const ExtraAdded = () => (
  <span className="flex h-8 gap-1 items-center text-neutral-600">
    <Icon className="text-sm" aria-hidden={true}>
      <MdCheckCircle />
    </Icon>
    <span className="text-sm font-semibold">
      <FormattedMessage {...messages.added} />
    </span>
  </span>
);
