import { Card, Divider } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Contract } from '@cooltra/station-based-api';

import { getContractTermMessage } from '~/utils/contract';
import {
  ContractOverviewDates,
  ContractOverviewUser,
  ContractTypeLabel,
  Log,
  SubscriptionContractState,
} from '~/common';

import messages from './messages';

export type SubscriptionContractOverviewProps = {
  contract: Contract;
};

export const SubscriptionContractOverview = ({
  contract: {
    monthsTerm,
    details,
    type,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    user,
  },
}: SubscriptionContractOverviewProps) => (
  <div
    className="mx-auto max-w-4xl pt-4 pb-10"
    data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW"
  >
    <div className="grid grid-cols-2 gap-6 mb-6">
      <Card className="p-8" data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_TERMS">
        <span className="block text-xs text-neutral-500 font-medium mb-2">
          <FormattedMessage {...messages.subscription} />
        </span>
        <span className="block text-2xl font-semibold text-neutral-800 mb-2">
          <FormattedMessage {...getContractTermMessage(monthsTerm)} />
        </span>
        <SubscriptionContractState />
      </Card>
    </div>
    <Card className="flex" data-testid="SUBSCRIPTION_CONTRACT_OVERVIEW_DETAILS">
      {type && <ContractTypeLabel type={type} />}
      <div className="w-48 lg:w-60 shrink-0 px-8 lg:px-10 py-8 bg-neutral-100/20 flex flex-col gap-64">
        {user && <ContractOverviewUser user={user} />}
      </div>
      <div className="w-full pb-8 px-8 pt-16">
        {details && <ContractOverviewDates details={details} />}
      </div>
    </Card>
    <div className="flex pt-5 gap-10">
      {createdAt && (
        <div className="max-w-56 truncate">
          <Log
            action="create"
            user="operator"
            userId={createdBy}
            date={createdAt}
          />
        </div>
      )}
      {updatedAt && updatedBy && (
        <>
          <div className="py-1">
            <Divider direction="vertical" className="h-full" />
          </div>
          <div className="max-w-56 truncate">
            <Log
              action="update"
              user="operator"
              userId={updatedBy}
              date={updatedAt}
            />
          </div>
        </>
      )}
    </div>
  </div>
);
