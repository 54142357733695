import { FormSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const SubscriptionDeliveryServicePoint = () => {
  const { servicePointsOptions, isLoading } = useServicePointOptions();

  return (
    <FormSelectField
      id="delivery"
      name="pickup"
      disabled={isLoading}
      options={servicePointsOptions}
      label={
        <InputLabel htmlFor="delivery">
          <FormattedMessage {...messages.servicePoint} />
        </InputLabel>
      }
    />
  );
};
