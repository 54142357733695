import { useUserStatesQuery } from '@cooltra/api';
import { MdError } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { Link } from '@cooltra/ui';

import messages from './messages';

export type ReviewPreviousHistoryWarningProps = {
  userId: string;
};

export const ReviewPreviousHistoryWarning = ({
  userId,
}: ReviewPreviousHistoryWarningProps) => {
  const { data } = useUserStatesQuery(userId);

  if (!data) return null;

  const hasBeenRejected = data.some((state) => state.type === 'REJECTED');
  const hasBeenAskedForCorrections = data.some(
    (state) => state.type === 'CORRECTIONS_REQUESTED'
  );

  const hasWarning = hasBeenRejected || hasBeenAskedForCorrections;

  const getMessage = () => {
    let messageToShow = messages.correctionsRequestedBefore;

    if (hasBeenRejected) {
      messageToShow = messages.rejectWarning;
    }

    return messageToShow;
  };

  return (
    <>
      {hasWarning && (
        <span className="inline-flex items-center gap-2">
          <MdError
            className={`${hasBeenRejected ? 'text-danger-500' : 'text-warning-500'} text-xl`}
          />
          <FormattedMessage
            {...getMessage()}
            values={{
              userStateLink: (
                <Link href={`/users/${userId}/account/states`}>
                  <FormattedMessage {...messages.historyLink} />
                </Link>
              ),
            }}
          />
        </span>
      )}
    </>
  );
};
