import { AlarmType } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export default defineMessages<AlarmType>({
  UNLOCKED: {
    defaultMessage: 'Unlocked',
  },
  STOLEN: {
    defaultMessage: 'Stolen',
  },
  OFFLINE: {
    defaultMessage: 'Offline',
  },
  BATTERY_UNLOCKED: {
    defaultMessage: 'Battery unlocked',
  },
});
