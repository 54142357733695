import { defineMessages } from 'react-intl';

export default defineMessages({
  accessoryIncludedOnAPaidTicket: {
    defaultMessage:
      'This accessory is included on a paid ticket, you must refunded it before add/remove it.',
  },
  accessoryIncludedOnAAwaitingTicket: {
    defaultMessage:
      'This accessory is included on a stripe QR payment, you must cancel it before add/remove it.',
  },
});
