import { defineMessages } from 'react-intl';

export default defineMessages({
  checksIn: {
    defaultMessage: 'Delivery checks',
  },
  openDetails: {
    defaultMessage: 'Go to vehicle assigned {licensePlate}',
  },
});
