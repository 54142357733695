import { useToggle } from '@cooltra/hooks';
import {
  usePatchContractAccessoriesMutation,
  usePatchSubscriptionAccessoriesMutation,
} from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';
import { useParams } from 'react-router-dom';

import { useNotification } from '~/hooks';

export type AccessoryCounterProps = {
  accessoryId: string;
  disabled?: boolean;
  quantity: number;
  limit: number;
  isSubscription?: boolean;
};

export const AccessoryCounter = ({
  accessoryId,
  disabled = false,
  quantity,
  limit,
  isSubscription = false,
}: AccessoryCounterProps) => {
  const { contractId = '' } = useParams();
  const { addErrorNotification } = useNotification();

  const [isAdding, { toggleOn: startAdding, toggleOff: endAdding }] =
    useToggle();
  const [isRemoving, { toggleOn: startRemoving, toggleOff: endRemoving }] =
    useToggle();

  const contractMutation = usePatchContractAccessoriesMutation(contractId);
  const subscriptionMutation =
    usePatchSubscriptionAccessoriesMutation(contractId);

  const mutateAsync = isSubscription
    ? subscriptionMutation.mutateAsync
    : contractMutation.mutateAsync;

  const removeOne = () => {
    startRemoving();
    mutateAsync({ accessoryId, quantity: quantity - 1 })
      .catch(() => addErrorNotification())
      .finally(() => endRemoving());
  };

  const addOne = () => {
    startAdding();
    mutateAsync({ accessoryId, quantity: quantity + 1 })
      .catch(() => addErrorNotification())
      .finally(() => endAdding());
  };

  return (
    <div className="flex items-center gap-2">
      <Button
        disabled={quantity === 0 || disabled}
        onClick={removeOne}
        loading={isRemoving}
      >
        -
      </Button>
      <span data-testid="ACCESSORY_COUNTER" className="w-8 text-center">
        {quantity}
      </span>
      <Button
        disabled={quantity >= limit || disabled}
        onClick={addOne}
        loading={isAdding}
      >
        +
      </Button>
    </div>
  );
};
