import { defineMessages } from 'react-intl';

export default defineMessages({
  depositRetained: {
    defaultMessage: 'Deposit retained',
  },
  depositCharged: {
    defaultMessage: 'Deposit charged',
  },
  retentionValidUntil: {
    defaultMessage: 'Retention valid until: {date}',
  },
  depositExpired: {
    defaultMessage: 'Automatically released',
  },
});
