import { DepositMethod } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages<DepositMethod>({
  TPV: {
    defaultMessage: 'TPV',
  },
  BOOKING_CARD: {
    defaultMessage: 'Stripe card',
  },
  STRIPE_QR: {
    defaultMessage: 'Stripe QR',
  },
});
