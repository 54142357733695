import { defineMessages } from 'react-intl';

export default defineMessages({
  term: {
    defaultMessage: 'Term',
  },
  months: {
    defaultMessage: '{months, plural, one {# month} other {# months}}',
  },
});
