import { defineMessages } from 'react-intl';

export default defineMessages({
  coverage: {
    defaultMessage: 'Coverage',
  },
  accessories: {
    defaultMessage: 'Accessories',
  },
});
