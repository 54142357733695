import { defineMessages } from 'react-intl';
import { ContractAnnexType } from '@cooltra/station-based-api';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  signedOn: {
    defaultMessage: 'Signed on {date}',
  },
  seeAnnex: {
    defaultMessage: 'See annex',
  },
  by: {
    defaultMessage: 'by',
  },
});

export const signedAnnexTypesMessages = defineMessages<ContractAnnexType>({
  EXTENSION: {
    defaultMessage: 'Signed extension of {days} days',
  },
  CHANGE_VEHICLE: {
    defaultMessage: 'Signed change vehicle extension',
  },
});

export const signedAnnexTypesMessagesByEmail =
  defineMessages<ContractAnnexType>({
    EXTENSION: {
      defaultMessage: 'Signed extension of {days} days by email',
    },
    CHANGE_VEHICLE: {
      defaultMessage: 'Signed change vehicle extension by email',
    },
  });
