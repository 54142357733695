import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    defaultMessage: 'Add',
  },
  addedSuccessfully: {
    defaultMessage: 'The penalty has been added correctly',
  },
});
