import { useIntl } from 'react-intl';
import { useControls } from 'react-zoom-pan-pinch';
import { MdOpenInFull, MdRotateLeft, MdRotateRight } from 'react-icons/md';

import { PhotoIconButton } from '../PhotoIconButton';
import messages from '../messages';

type ControlsProps = {
  rotateLeft: () => void;
  rotateRight: () => void;
  expandImage: () => void;
};

export const Controls = ({
  expandImage,
  rotateLeft,
  rotateRight,
}: ControlsProps) => {
  const { formatMessage } = useIntl();
  const { resetTransform } = useControls();

  const expandImageHandler = () => {
    resetTransform();
    expandImage();
  };

  return (
    <div className="w-full h-14 absolute inset-0 rounded-md z-20">
      <div className="opacity-0 group-hover:opacity-60 group-focus-within:opacity-60 flex gap-2 p-3 justify-center bg-neutral-1000 rounded-md transition-opacity">
        <PhotoIconButton
          aria-label={formatMessage(messages.fullScreen)}
          onClick={expandImageHandler}
        >
          <MdOpenInFull />
        </PhotoIconButton>
        <PhotoIconButton
          aria-label={formatMessage(messages.rotateLeft)}
          onClick={rotateLeft}
        >
          <MdRotateLeft />
        </PhotoIconButton>
        <PhotoIconButton
          aria-label={formatMessage(messages.rotateRight)}
          onClick={rotateRight}
        >
          <MdRotateRight />
        </PhotoIconButton>
      </div>
    </div>
  );
};
