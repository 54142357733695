import { ReactNode } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  useContractPaymentsQuery,
  useContractQuery,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { SpinnerScreen } from '@cooltra/ui';
import { Helmet } from 'react-helmet';

import { ErrorPage, Four0Four, QRCode } from '~/common';
import { oneDay } from '~/utils/time';

import { ContractChecksAndDamages } from '../ContractChecksAndDamages/ContractChecksAndDamages';
import { ContractDetails } from '../ContractDetails';
import {
  ContractDriverDetails,
  ContractSecondDriverDetails,
} from '../ContractDriverDetails';
import { ContractExtras } from '../ContractExtras';
import { ContractOverview } from '../ContractOverview';
import { ContractPayments } from '../ContractPayments';
import { ContractSidebar } from '../ContractSidebar/ContractSidebar';
import { ContractVehicleTypes } from '../ContractVehicleTypes';
import { ContractClickAndRide } from '../ContractClickAndRide';

import { ContractNavbar } from './ContractNavbar';
import { RedirectOnDisabled } from './RedirectOnDisabled';

export const Contract = () => {
  const { contractId = '' } = useParams();

  const { data: servicePoints, ...servicePointsQuery } = useServicePointsQuery({
    staleTime: oneDay,
  });

  const { data: contract, ...contractQuery } = useContractQuery(contractId, {
    enabled: !!contractId,
  });

  const { data: payments = [], ...paymentsQuery } = useContractPaymentsQuery(
    contractId,
    { enabled: !!contract && !contract.isMigrated }
  );

  const hasClickAndRide = contract?.hasClickAndRide;

  if (
    contractQuery.isError ||
    paymentsQuery.isError ||
    servicePointsQuery.isError
  ) {
    return <ErrorPage />;
  }

  if (!contract || !servicePoints || (!payments && !contract.isMigrated)) {
    return <SpinnerScreen />;
  }

  const getRedirectToOverviewOnDisabled = (children: ReactNode) => (
    <RedirectOnDisabled contract={contract} redirectPath="../overview">
      {children}
    </RedirectOnDisabled>
  );

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="h-screen-content flex w-full">
        <ContractSidebar contract={contract} />
        <div className="flex flex-col h-screen-content w-full">
          <ContractNavbar contract={contract} />
          <div className="w-full gap-8 flex-1 px-4 lg:px-8 pt-4 lg:pt-8 overflow-y-scroll">
            <div className="w-full">
              <Routes>
                <Route
                  path="/overview"
                  element={
                    <RedirectOnDisabled
                      contract={contract}
                      redirectPath="../details"
                    >
                      <ContractOverview contract={contract} />
                      <QRCode />
                    </RedirectOnDisabled>
                  }
                />
                <Route
                  path="/details"
                  element={getRedirectToOverviewOnDisabled(
                    <ContractDetails contract={contract} />
                  )}
                />
                <Route
                  path="/driver"
                  element={getRedirectToOverviewOnDisabled(
                    <ContractDriverDetails contract={contract} />
                  )}
                />
                <Route
                  path="/second-driver"
                  element={getRedirectToOverviewOnDisabled(
                    <ContractSecondDriverDetails contract={contract} />
                  )}
                />
                <Route
                  path="/vehicles"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <ContractVehicleTypes contract={contract} />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/extras"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <ContractExtras contract={contract} payments={payments} />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/payments"
                  element={getRedirectToOverviewOnDisabled(
                    <>
                      <ContractPayments
                        contract={contract}
                        payments={payments}
                      />
                      <QRCode />
                    </>
                  )}
                />
                <Route
                  path="/checks-damages"
                  element={getRedirectToOverviewOnDisabled(
                    <ContractChecksAndDamages contract={contract} />
                  )}
                />
                {hasClickAndRide && (
                  <Route
                    path="/click-and-ride"
                    element={getRedirectToOverviewOnDisabled(
                      <ContractClickAndRide contract={contract} />
                    )}
                  />
                )}
                <Route path="*" element={<Four0Four />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
