import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useUserQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { FormProvider } from '@cooltra/form';
import { SpinnerScreen } from '@cooltra/ui';

import { canUserBeReviewed } from '~/utils/users';

import { ErrorPage, Four0Four, Four0Three } from '../../../ErrorPage';
import { ReviewLayout } from '../ReviewLayout/ReviewLayout';
import { AddressCheck } from '../AddressCheck/AddressCheck';
import { DriverLicenseCheck } from '../DriverLicenseCheck/DriverLicenseCheck';
import { IdCheck } from '../IdCheck/IdCheck';
import { ReviewHeader } from '../ReviewHeader';
import { PhotosGrid } from '../PhotosGrid/PhotosGrid';
import { ReviewComments } from '../ReviewComments/ReviewComments';
import { ReviewFormType } from '../review-form-types';
import { PhotosCheck, IgnoreDriverLicense } from '../PhotosCheck';
import { RejectOverride } from '../RejectOverride';
import { ReviewPreviousHistoryWarning } from '../ReviewPreviousHistoryWarning/ReviewPreviousHistoryWarning';

import { parseReviewFormValues } from './review-form-values';
import messages from './messages';
import { validateActivation, validateNonActivation } from './validate';

type ReviewFormProps = {
  userId: string;
  onFinish: () => void;
};

export const ReviewForm = ({ userId, onFinish }: ReviewFormProps) => {
  const [isDriverLicenseIgnored, ignoreDriverLicense] = useState(false);
  const [formType, setFormType] = useState<ReviewFormType>('activate');
  const intl = useIntl();
  const {
    data: user,
    isError,
    error,
  } = useUserQuery(userId, {
    staleTime: Infinity,
  });

  if (!userId) {
    return <Four0Four />;
  }

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (!user) {
    return <SpinnerScreen />;
  }

  const { system, photos, state, version } = user;

  if (!canUserBeReviewed(state)) {
    return (
      <ErrorPage
        title={intl.formatMessage(messages.errorTitle)}
        error={intl.formatMessage(messages.error)}
      />
    );
  }

  const hasDriverLicense = !!photos.driverLicenseFront;
  const hasIdentityCard = !!photos.idCardFront;

  return (
    <div data-testid="USER_REVIEW">
      <FormProvider
        initialValues={parseReviewFormValues(user)}
        validate={
          formType === 'activate'
            ? validateActivation(intl, {
                hasDriverLicense,
                isDriverLicenseIgnored,
                hasIdentityCard,
              })
            : validateNonActivation(intl)
        }
      >
        <ReviewLayout
          renderPhotos={
            <PhotosGrid
              isDriverLicenseIgnored={isDriverLicenseIgnored}
              photos={photos}
            />
          }
          renderHeader={
            <ReviewHeader
              formType={formType}
              setFormType={setFormType}
              user={user}
              onFinish={onFinish}
            />
          }
          renderPhotosCheck={
            <>
              <PhotosCheck
                hasDriverLicense={hasDriverLicense}
                hasIdentityCard={hasIdentityCard}
              />
              <IgnoreDriverLicense onIgnore={ignoreDriverLicense} />
            </>
          }
          renderIdCheck={
            hasIdentityCard ? (
              <IdCheck system={system} userId={userId} />
            ) : undefined
          }
          renderAddressCheck={<AddressCheck />}
          renderDriverLicenseCheck={
            hasDriverLicense && !isDriverLicenseIgnored ? (
              <DriverLicenseCheck system={system} userId={userId} />
            ) : undefined
          }
          renderComments={
            <>
              <ReviewComments />
              {formType !== 'reject' && (
                <RejectOverride
                  version={version}
                  userId={userId}
                  onFinish={onFinish}
                />
              )}
            </>
          }
          renderPreviousHistoryWarning={
            <ReviewPreviousHistoryWarning userId={userId} />
          }
        />
      </FormProvider>
    </div>
  );
};
