import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { FormNativeSelectField, useFormContext } from '@cooltra/form';
import { ContractStatus } from '@cooltra/station-based-api';

import { daySlots } from '~/utils/time';

import { DateTimePickerFormValues } from '../types';

import messages from './messages';

export type PickUpTimeProps = {
  contractStatus: ContractStatus;
};

export const PickupTime = ({ contractStatus }: PickUpTimeProps) => {
  const {
    values: { pickUpDate },
  } = useFormContext<DateTimePickerFormValues>();

  if (!pickUpDate) {
    return null;
  }

  return (
    <FormNativeSelectField
      className="w-40"
      disabled={contractStatus === 'ACTIVE'}
      options={[
        { label: '', value: '', disabled: true },
        ...daySlots.map((option) => ({
          value: option,
          label: option,
        })),
      ]}
      id="delivery-time"
      label={
        <InputLabel htmlFor="delivery-time">
          <FormattedMessage {...messages.delivery} />
        </InputLabel>
      }
      name="pickUpTime"
    />
  );
};
