import { defineMessages } from 'react-intl';

export default defineMessages({
  valueAdjustment: {
    defaultMessage: 'Price adjustments',
  },
  remove: {
    defaultMessage: 'Remove',
  },
  removeSuccessNotification: {
    defaultMessage: 'Price adjustment removed successfully',
  },
});
