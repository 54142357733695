import { Card } from '@cooltra/ui';
import { ContractAnnex, ContractUser } from '@cooltra/station-based-api';
import { MdCheckCircle } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { DateTime, WithOperator } from '~/common';

import { SeeAnnexPDFButton } from './SeeAnnexPDFButton';
import messages, {
  signedAnnexTypesMessages,
  signedAnnexTypesMessagesByEmail,
} from './messages';

export type SignedProps = {
  annex: ContractAnnex;
  contractId: string;
  user: ContractUser | null;
};

export const Signed = ({
  contractId,
  annex: { id: annexId, type, days, signedAt, signedWith },
  user,
}: SignedProps) => (
  <Card key={signedAt} className="flex items-center p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdCheckCircle
        className="text-success-500 text-xl"
        data-testid="SIGNED_ANNEX"
      />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.annex} />
        <p className="flex items-center gap-1 font-normal text-success-800 text-sm">
          {signedWith ? (
            <FormattedMessage
              {...signedAnnexTypesMessages[type]}
              values={{ days }}
            />
          ) : (
            <FormattedMessage
              {...signedAnnexTypesMessagesByEmail[type]}
              values={{ days }}
            />
          )}
        </p>
      </h4>
    </div>
    <div className="flex flex-col items-end">
      <p className="flex items-center gap-1 font-regular text-neutral-600 text-sm">
        <FormattedMessage
          {...messages.signedOn}
          values={{
            date: (
              <DateTime
                date={signedAt}
                dateClassName="text-neutral-600 text-sm"
                timeClassName="text-neutral-600 text-sm"
              />
            ),
          }}
        />
      </p>
      {signedWith ? (
        <WithOperator id={signedWith} />
      ) : (
        <span className="text-sm text-neutral-600">
          <span className="mr-1">
            <FormattedMessage {...messages.by} />
          </span>
          <span className="text-neutral-800 font-medium truncate">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
        </span>
      )}
    </div>
    <SeeAnnexPDFButton contractId={contractId} annexId={annexId} />
  </Card>
);
