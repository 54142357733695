import { IntlShape } from 'react-intl';
import { Vehicle } from '@cooltra/api';
import { FormErrors } from '@cooltra/form';

import { createSetError, validateDate, validateRequired } from '~/validation';

export type EditVehicleFormValues = Pick<
  Vehicle,
  | 'model'
  | 'telematics'
  | 'externalId'
  | 'licensePlate'
  | 'telematicsId'
  | 'identificationNumber'
  | 'insuranceExpiresAt'
  | 'cityHallPublished'
  | 'qrId'
>;

type EditVehicleFormFieldName = keyof EditVehicleFormValues;

const requiredFields: EditVehicleFormFieldName[] = [
  'model',
  'telematics',
  'externalId',
];

export const validateEditVehicleForm =
  (intl: IntlShape) =>
  (values: EditVehicleFormValues): FormErrors<EditVehicleFormValues> => {
    const errors: FormErrors<EditVehicleFormValues> = {};
    const setError = createSetError(errors);
    requiredFields.forEach((fieldName) => {
      setError(fieldName, validateRequired(intl, values[fieldName]));
    });
    if (values.insuranceExpiresAt !== '') {
      setError(
        'insuranceExpiresAt',
        validateDate(intl, values.insuranceExpiresAt)
      );
    }
    return errors;
  };

export const getVehicleInitialValues = ({
  model,
  telematics,
  externalId,
  licensePlate,
  telematicsId,
  identificationNumber,
  insuranceExpiresAt,
  cityHallPublished,
  qrId,
}: Pick<
  Vehicle,
  | 'model'
  | 'telematics'
  | 'externalId'
  | 'licensePlate'
  | 'telematicsId'
  | 'identificationNumber'
  | 'insuranceExpiresAt'
  | 'cityHallPublished'
  | 'qrId'
>): EditVehicleFormValues => ({
  model,
  telematics,
  externalId,
  licensePlate,
  telematicsId,
  identificationNumber,
  insuranceExpiresAt,
  cityHallPublished,
  qrId,
});
