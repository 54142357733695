import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button, Modal } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';
import { useContractQuery } from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import messages from './messages';

export type RemoveVehicleTypeButtonProps = {
  onRemove: () => Promise<void>;
  disabled?: boolean;
};

export const RemoveVehicleTypeButton = ({
  onRemove,
  disabled = false,
}: RemoveVehicleTypeButtonProps) => {
  const { contractId = '' } = useParams();

  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();
  const [isRemoving, { toggleOn: startRemoving, toggleOff: endRemoving }] =
    useToggle();

  const { addErrorNotification } = useNotification();

  const { data: contract } = useContractQuery(contractId, {
    enabled: false,
  });

  const removeVehicleType = () => {
    startRemoving();
    onRemove()
      .catch(() => addErrorNotification())
      .finally(() => endRemoving());
  };

  if (!contract) {
    return null;
  }

  const { accessories, insurance } = contract;

  return (
    <>
      {!!accessories?.length || insurance !== 'BASIC' ? (
        <Button
          variant="danger"
          emphasis="low"
          onClick={toggleOn}
          disabled={disabled}
        >
          <FormattedMessage {...messages.remove} />
        </Button>
      ) : (
        <Button
          variant="danger"
          emphasis="low"
          onClick={removeVehicleType}
          loading={isRemoving}
          disabled={disabled}
        >
          <FormattedMessage {...messages.remove} />
        </Button>
      )}
      <Modal
        className="max-w-sm min-w-xs"
        isOpen={isModalOpen}
        onRequestClose={toggleOff}
      >
        <div className="text-center pb-8 px-8">
          <h6 className="text-neutral-700 text-lg font-semibold mb-2">
            <FormattedMessage {...messages.confirmation} />
          </h6>
          <p className="text-neutral-600 text-sm mb-8">
            <FormattedMessage {...messages.disclaimer} />
          </p>
          <div className="flex justify-center gap-4">
            <Button onClick={toggleOff}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <Button
              loading={isRemoving}
              emphasis="high"
              onClick={removeVehicleType}
            >
              <FormattedMessage {...messages.confirm} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
