import { defineMessages } from 'react-intl';

export default defineMessages({
  discard: {
    defaultMessage: 'Discard',
  },
  confirmTitle: {
    defaultMessage: 'Discard draft',
  },
  confirmContent: {
    defaultMessage: 'Are you sure you want to discard this draft?',
  },
  success: {
    defaultMessage: 'Draft discarded successfully',
  },
});
