import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type DayCircleProps = HTMLAttributes<HTMLDivElement> & {
  active: boolean;
  today: boolean;
  disabled?: boolean;
};

export const DayCircle = ({
  className,
  active,
  today = false,
  disabled = false,
  ...rest
}: DayCircleProps) => (
  <span
    className={classNames(
      'relative flex h-10 w-10 shrink-0 items-center justify-center rounded-full ring-inset ring-primary-200 group-focus-visible:ring',
      today && 'font-semibold',
      active
        ? 'text-neutral-0'
        : disabled
          ? 'text-neutral-200'
          : 'text-neutral-800',
      active ? 'bg-primary-400' : 'bg-transparent',
      className
    )}
    {...rest}
  />
);
