import { ReactNode } from 'react';
import { Card } from '@cooltra/ui';

import './review-layout.css';

export type ReviewLayoutProps = {
  renderHeader: ReactNode;
  renderPhotos: ReactNode;
  renderPhotosCheck: ReactNode;
  renderIdCheck: ReactNode;
  renderAddressCheck: ReactNode;
  renderDriverLicenseCheck?: ReactNode;
  renderComments?: ReactNode;
  renderPreviousHistoryWarning?: ReactNode;
};

export const ReviewLayout = ({
  renderHeader,
  renderPhotos,
  renderPhotosCheck,
  renderIdCheck,
  renderAddressCheck,
  renderDriverLicenseCheck,
  renderComments,
  renderPreviousHistoryWarning,
}: ReviewLayoutProps) => (
  <div className="container min-w-screen-lg max-w-screen-2xl">
    <div className="sticky bg-neutral-50 header px-4 pt-4 z-10">
      <Card className="p-4 bg-neutral-0 shadow-md">{renderHeader}</Card>
    </div>
    <div className="z-0">
      <div className="grid grid-cols-5 gap-4 px-4">
        <div className="col-span-3">
          <div className="pt-4 sticky photos">
            <Card className="p-4">{renderPhotos}</Card>
          </div>
        </div>
        <div className="col-span-2">
          <div className="py-4 grid gap-4">
            {renderPreviousHistoryWarning}
            {renderPhotosCheck}
            {renderIdCheck}
            {renderAddressCheck}
            {renderDriverLicenseCheck}
            {renderComments}
          </div>
        </div>
      </div>
    </div>
  </div>
);
