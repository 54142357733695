import { FormattedMessage } from 'react-intl';
import {
  ContractPayment,
  useContractPaymentsQuery,
} from '@cooltra/station-based-api';
import { Button } from '@cooltra/ui';
import { MdQrCode } from 'react-icons/md';

import { Amount, DateTime } from '~/common';
import { useQRPayment } from '~/libs/qr-payment';

import messages from './messages';
import { CancelAwaitingPaymentButton } from './CancelAwaitingPayment/CancelAwaitingPayment';

type AwaitingPaymentTicketProps = {
  contractId: string;
  ticket: ContractPayment;
};

export const AwaitingPaymentTicket = ({
  contractId,
  ticket,
}: AwaitingPaymentTicketProps) => {
  const { refetch, isRefetching } = useContractPaymentsQuery(contractId, {
    enabled: false,
  });
  const { openModal } = useQRPayment();
  const { totalPrice } = ticket;
  const verifyDeposit = () => refetch();
  const openQR = () => openModal(ticket.id);

  return (
    <div
      className="flex justify-between items-end mb-8"
      data-testid="AWAITING_PAYMENT_CONTAINER"
    >
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          <FormattedMessage {...messages.title} />
        </span>
        <span className="text-warning-600">
          <FormattedMessage {...messages.waitingStripeWithhold} />
        </span>
        <div className="flex items-center gap-3 text-neutral-500">
          <span className="flex items-center gap-1 ">
            <FormattedMessage {...messages.createdAt} />
            <DateTime
              date={ticket.markedAsAwaitingPaymentAt}
              dateClassName="text-base text-neutral-500"
              timeClassName="text-base text-neutral-500"
            />
          </span>
          <span className="text-neutral-100">|</span>
          <Button
            onClick={openQR}
            emphasis="low"
            size="sm"
            leadingIcon={<MdQrCode />}
          >
            <FormattedMessage {...messages.showStripeQR} />
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-3 items-end">
        <Amount
          className="font-semibold text-xl text-neutral-800"
          {...totalPrice}
        />
        <div className="flex items-center gap-3">
          <CancelAwaitingPaymentButton
            contractId={contractId}
            ticketId={ticket.id}
          />
          <Button loading={isRefetching} size="sm" onClick={verifyDeposit}>
            <FormattedMessage {...messages.verify} />
          </Button>
        </div>
      </div>
    </div>
  );
};
