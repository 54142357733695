import { Card, FakeInput } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { ContractStatus } from '@cooltra/station-based-api';

import { DateTimePicker } from '../DateTimePicker';

import messages from './messages';

type ContractDatesFormValues = {
  pickup?: string;
  pickUpDate?: Date;
  pickUpTime?: string;
  dropOffDate?: Date;
  dropOffTime?: string;
};

export type ContractDatesProps = {
  disabledDelivery?: boolean;
  disabledReturn?: boolean;
  contractStatus: ContractStatus;
};

export const ContractDates = ({
  disabledDelivery = false,
  disabledReturn = false,
  contractStatus,
}: ContractDatesProps) => {
  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();
  const { formatMessage, formatDate } = useIntl();

  const {
    isSubmitting,
    values: { pickup, dropOffDate, dropOffTime, pickUpDate, pickUpTime },
    touched,
    errors,
  } = useFormContext<ContractDatesFormValues>();

  const formatDateTime = (date?: Date, time?: string) => {
    if (!date || !time) {
      return '';
    }

    return `${formatDate(date, {
      day: 'numeric',
      month: 'short',
    })} - ${time}`;
  };

  return (
    <div>
      <h2 className="text-xl text-neutral-700 mb-4">
        <FormattedMessage {...messages.dateAndTime} />
      </h2>
      <DateTimePicker
        isOpen={isModalOpen}
        closeModal={toggleOff}
        contractStatus={contractStatus}
      />
      <Card className="pt-8 pb-14 px-14 flex gap-4 items-start">
        <button
          disabled={disabledDelivery}
          type="button"
          onClick={toggleOn}
          className="w-40"
        >
          <FakeInput
            disabled={isSubmitting || !pickup || disabledDelivery}
            label={formatMessage(messages.delivery)}
            placeholder={formatMessage(messages.dateAndTime)}
            value={formatDateTime(pickUpDate, pickUpTime)}
            invalid={
              (touched.pickUpDate || touched.pickUpTime) &&
              (!!errors.pickUpDate || !!errors.pickUpTime)
            }
            error={
              touched.pickUpDate || touched.pickUpTime
                ? errors.pickUpDate || errors.pickUpTime
                : undefined
            }
          />
        </button>
        <button
          disabled={disabledReturn}
          type="button"
          onClick={toggleOn}
          className="w-40"
        >
          <FakeInput
            disabled={isSubmitting || !pickup || disabledReturn}
            label={formatMessage(messages.return)}
            placeholder={formatMessage(messages.dateAndTime)}
            value={formatDateTime(dropOffDate, dropOffTime)}
            invalid={
              (touched.dropOffDate || touched.dropOffTime) &&
              (!!errors.dropOffDate || !!errors.dropOffTime)
            }
            error={
              touched.dropOffDate || touched.dropOffTime
                ? errors.dropOffDate || errors.dropOffTime
                : undefined
            }
          />
        </button>
      </Card>
    </div>
  );
};
