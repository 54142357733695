import { Contract } from '@cooltra/station-based-api';

import { ChecksIn, ChecksOut, Damages, Penalties } from '~/common';

export type SubscriptionContractChecksAndDamagesProps = {
  contract: Contract;
};

export const SubscriptionContractChecksAndDamages = ({
  contract,
}: SubscriptionContractChecksAndDamagesProps) => {
  const hasToDisplayChecksOut =
    contract.status === 'ACTIVE' || contract.status === 'CLOSED';
  const isEnabled = ['ACTIVE', 'BOOKED', 'DRAFT'].includes(contract.status);

  return (
    <>
      {hasToDisplayChecksOut ? (
        <>
          <ChecksOut contract={contract} disabled={!isEnabled} />
          <Penalties contract={contract} disabled={!isEnabled} />
        </>
      ) : (
        <ChecksIn contract={contract} disabled={!isEnabled} />
      )}
      <Damages
        vehicleId={contract.vehicle?.assignedVehicle?.vehicleId}
        disabled={!isEnabled}
      />
    </>
  );
};
