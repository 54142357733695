import { Card } from '@cooltra/ui';
import { ContractAnnex, ContractUser } from '@cooltra/station-based-api';
import { MdError } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { ByOperator, DateTime } from '~/common';

import messages, { sentAnnexTypesMessages } from './messages';
import { CancelButton } from './CancelButton';

export type SentProps = {
  annex: ContractAnnex;
  contractId: string;
  user: ContractUser | null;
};

export const Sent = ({
  annex: { id, type, days, sentAt, sentBy },
  contractId,
  user,
}: SentProps) => (
  <Card key={sentAt} className="flex items-center p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdError className="text-warning-500 text-xl" data-testid="SENT_ANNEX" />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.annex} />
        <p className="flex items-center gap-1 font-normal text-warning-700 text-sm">
          <FormattedMessage
            {...sentAnnexTypesMessages[type]}
            values={{ days }}
          />
        </p>
      </h4>
    </div>
    <div className="flex flex-col items-end">
      <p className="flex items-center gap-1 font-regular text-neutral-600 text-sm">
        <FormattedMessage
          {...messages.sentOn}
          values={{
            date: (
              <DateTime
                date={sentAt}
                dateClassName="text-neutral-600 text-sm"
                timeClassName="text-neutral-600 text-sm"
              />
            ),
          }}
        />
      </p>
      {sentBy ? (
        <ByOperator id={sentBy} />
      ) : (
        <span className="text-sm text-neutral-600">
          <span className="mr-1">
            <FormattedMessage {...messages.by} />
          </span>
          <span className="text-neutral-800 font-medium truncate">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
        </span>
      )}
    </div>
    <CancelButton contractId={contractId} annexId={id} />
  </Card>
);
