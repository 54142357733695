import { Icon, Card, Badge } from '@cooltra/ui';
import { AlarmListItem } from '@cooltra/api';
import { RouterBaseLink } from '@cooltra/navigation';
import { MdCheck } from 'react-icons/md';

import { DateTime, ModelImage, TimeAgo } from '~/common';

import { AlarmRowLayout } from '../AlarmRowLayout/AlarmRowLayout';
import { AlarmTypeMessage } from '../AlarmTypeMessage/AlarmTypeMessage';

export type AlarmCardProps = {
  alarm: AlarmListItem;
};

export const AlarmCard = ({
  alarm: { vehicle, types, createdAt },
}: AlarmCardProps) => {
  return (
    <Card>
      <AlarmRowLayout
        vehicle={
          <div className="h-24 w-full flex items-center justify-start">
            <RouterBaseLink
              to={`/vehicles/${vehicle.vehicleId}`}
              className="flex flex-col items-center shrink-0"
            >
              <ModelImage
                model={vehicle.model}
                identificationNumber={vehicle.identificationNumber}
                className="h-10"
              />
            </RouterBaseLink>
            <div className="pl-4 text-xs text-neutral-900 text-left">
              <span className="block font-semibold mb-1">
                {vehicle.externalId}
              </span>
              <div className="flex items-center">
                <span>{vehicle.licensePlate}</span>
                {vehicle.cityHallPublished && (
                  <Icon className="ml-3 text-neutral-400 bg-neutral-50 w-5 h-4 rounded inline-block">
                    <MdCheck />
                  </Icon>
                )}
              </div>
            </div>
          </div>
        }
        alarms={
          <div className="flex gap-2">
            {types.map((type) => (
              <Badge key={type} variant="danger" emphasis="medium">
                <span className="text-sm">
                  <AlarmTypeMessage type={type} />
                </span>
              </Badge>
            ))}
          </div>
        }
        duration={
          <div className="w-full flex flex-col gap-1 text-right">
            <span className="text-xs font-semibold">
              <TimeAgo ISODate={createdAt} />
            </span>
            <span className="text-xs">
              <DateTime date={createdAt} />
            </span>
          </div>
        }
      />
    </Card>
  );
};
