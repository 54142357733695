import { FormSelectField } from '@cooltra/form';
import { Card, InputLabel } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const ReturnServicePoint = () => {
  const { servicePointsOptions } = useServicePointOptions();

  return (
    <div className="w-full">
      <h2 className="text-xl text-neutral-700 mb-4">
        <FormattedMessage {...messages.return} />
      </h2>
      <Card className="pt-8 pb-14 px-14">
        <FormSelectField
          id="return"
          disabled={true}
          label={
            <InputLabel htmlFor="return">
              <FormattedMessage {...messages.servicePoint} />
            </InputLabel>
          }
          name="pickup"
          options={servicePointsOptions}
        />
      </Card>
    </div>
  );
};
