import { useServicePointsQuery } from '@cooltra/station-based-api';

export const useServicePointOptions = () => {
  const { data: servicePoints, isLoading } = useServicePointsQuery({
    enabled: false,
  });

  const servicePointsOptions = servicePoints
    ? servicePoints
        .map((servicePoint) => ({
          label: servicePoint.name,
          value: servicePoint.id,
        }))
        .sort((servicePoint, nextServicePoint) =>
          servicePoint.label.localeCompare(nextServicePoint.label)
        )
    : [];

  return { servicePointsOptions, isLoading };
};
