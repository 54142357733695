import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Address',
  },
  isAddressComplete: {
    defaultMessage: 'Is the address above complete?',
  },
  tooltipBody: {
    defaultMessage:
      'The address needs a street name and number. If either of them is missing but you can complete it based on the provided documents, please do so. Otherwise, the address is considered incomplete.',
  },
  complete: {
    defaultMessage: 'Complete',
  },
  incomplete: {
    defaultMessage: 'Incomplete',
  },
  seeOnGoogleMaps: {
    defaultMessage: 'See on Google Maps',
  },
});
