import { FormattedMessage, useIntl } from 'react-intl';
import {
  Contract,
  useSubscriptionAvailableVehiclesTypesQuery,
} from '@cooltra/station-based-api';
import { Message, Spinner } from '@cooltra/ui';
import { MdErrorOutline } from 'react-icons/md';
import { getVehicleName, vehicleImages } from '@cooltra/utils';

import { ErrorPage } from '~/common';

import { SubscriptionContractVehicleTypeCard } from '../SubscriptionContractVehicleTypeCard/SubscriptionContractVehicleTypeCard';
import { SelectedVehicleTypeCard } from '../SelectedVehicleTypeCard/SelectedVehicleTypeCard';
import { SubscriptionContractTermField } from '../SubscriptionContractTermField/SubscriptionContractTermField';
import { SubscriptionContractPlanField } from '../SubscriptionContractPlanField/SubscriptionContractPlanField';

import messages from './messages';

export type SubscriptionContractVehiclesProps = {
  contract: Contract;
};

export const SubscriptionContractVehicles = ({
  contract: { contractId, vehicle, monthsTerm, plan },
}: SubscriptionContractVehiclesProps) => {
  const { formatMessage } = useIntl();
  const {
    data: vehicleTypes = [],
    isLoading,
    isError,
  } = useSubscriptionAvailableVehiclesTypesQuery(contractId);

  if (isLoading) {
    return (
      <div className="h-80 w-full text-neutral-400 flex items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (vehicleTypes?.length === 0) {
    return (
      <div className="container flex justify-center py-16 tc max-w-5xl">
        <Message
          icon={<MdErrorOutline />}
          title={formatMessage(messages.vehicleNotFoundTitle)}
          text={formatMessage(messages.vehicleNotFoundContent)}
        />
      </div>
    );
  }

  return (
    <div data-testid="SUBSCRIPTION_CONTRACT_VEHICLE_TYPES">
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <h2 className="text-xl mb-6">
          <FormattedMessage {...messages.vehicle} />
        </h2>
        <div className="flex gap-4 mb-8">
          <SubscriptionContractTermField
            contractId={contractId}
            initialValue={monthsTerm}
          />
          <SubscriptionContractPlanField
            contractId={contractId}
            initialValue={plan}
          />
        </div>
        {vehicle ? (
          <SelectedVehicleTypeCard
            name={vehicle.name}
            contractId={contractId}
            vehicleTypeName={getVehicleName(vehicle.name)}
            vehicleTypeImage={vehicleImages[vehicle.name]}
            price={vehicle.subscriptionPrice!}
          />
        ) : (
          <div className="grid grid-cols-2 gap-4">
            {vehicleTypes.map((vehicleType) => (
              <SubscriptionContractVehicleTypeCard
                key={vehicleType.id}
                vehicle={vehicleType}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
