import { FormattedMessage } from 'react-intl';
import {
  Contract,
  useSubscriptionAvailableAccessoriesQuery,
  useSubscriptionAvailableInsurancesQuery,
} from '@cooltra/station-based-api';
import { Spinner } from '@cooltra/ui';

import { ErrorPage } from '~/common';

import { SubscriptionInsuranceCard } from '../SubscriptionInsuranceCard/SubscriptionInsuranceCard';
import { SubscriptionAccessoryCard } from '../SubscriptionAccessoryCard/SubscriptionAccessoryCard';

import messages from './messages';

export type SubscriptionContractExtrasProps = {
  contract: Contract;
};

export const SubscriptionContractExtras = ({
  contract,
}: SubscriptionContractExtrasProps) => {
  const { contractId } = contract;

  const {
    data: insurances = [],
    isLoading: areInsurancesLoading,
    isError: isInsurancesError,
  } = useSubscriptionAvailableInsurancesQuery(contractId, {
    enabled: !!contractId,
  });

  const {
    data: accessories = [],
    isLoading: areAccessoriesLoading,
    isError: isAccessoriesError,
  } = useSubscriptionAvailableAccessoriesQuery(contractId, {
    enabled: !!contractId,
    retry: false,
  });

  if (areInsurancesLoading || areAccessoriesLoading) {
    return (
      <div className="w-full text-center mt-8">
        <Spinner size="lg" />
      </div>
    );
  }

  if (isInsurancesError || isAccessoriesError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl">
            <FormattedMessage {...messages.coverage} />
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {insurances
            .sort(({ name }) => (name === 'BASIC' ? -1 : 1))
            .map((insurance) => (
              <SubscriptionInsuranceCard
                contract={contract}
                insurance={insurance}
                key={insurance.insuranceId}
              />
            ))}
        </div>
        <div className="mt-14 flex items-center justify-between mb-6">
          <h2 className="text-xl">
            <FormattedMessage {...messages.accessories} />
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {accessories.map(({ accessoryId, name, monthlyPrice, limit }) => {
            const subscriptionAccessory = contract.accessories?.find(
              ({ id }) => id === accessoryId
            );

            return (
              <SubscriptionAccessoryCard
                contract={contract}
                quantity={subscriptionAccessory?.quantity || 0}
                info={subscriptionAccessory?.info}
                key={accessoryId}
                accessoryId={accessoryId}
                name={name}
                monthlyPrice={monthlyPrice}
                limit={limit}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
