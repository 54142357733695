import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useCancelContractAnnexMutation } from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';
import { DeleteModal } from '~/common';

import messages from './messages';

export type CancelButtonProps = {
  contractId: string;
  annexId: string;
};

export const CancelButton = ({ contractId, annexId }: CancelButtonProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  const { addErrorNotification } = useNotification();
  const { mutateAsync, isPending } = useCancelContractAnnexMutation(
    contractId,
    annexId
  );

  const handleCancel = () => {
    mutateAsync()
      .then(() => toggleOff())
      .catch(() => addErrorNotification());
  };

  return (
    <>
      <Button variant="danger" onClick={toggleOn} disabled={isPending}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <DeleteModal
        onClose={toggleOff}
        onConfirm={handleCancel}
        isOpen={isOpen}
        isDeleting={isPending}
      />
    </>
  );
};
