import { Card } from '@cooltra/ui';
import { ContractDocument, ContractUser } from '@cooltra/station-based-api';
import { MdCheckCircle } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { DateTime, WithOperator } from '~/common';

import { SeeContractPDFButton } from './SeeContractPDFButton';
import messages from './messages';

export type SignedProps = {
  document: ContractDocument;
  contractId: string;
  isMigrated: boolean;
  user: ContractUser | null;
};

export const Signed = ({
  document: { id: documentId, sentAt, signedAt, signedWith },
  contractId,
  isMigrated,
  user,
}: SignedProps) => (
  <Card key={sentAt || signedAt} className="flex items-center p-5 gap-7">
    <div className="flex-1 flex items-center gap-4 pl-2">
      <MdCheckCircle
        className="text-success-500 text-xl"
        data-testid="SIGNED_DOCUMENT"
      />
      <h4 className="font-semibold text-neutral-800 text-base">
        <FormattedMessage {...messages.contract} />
        <p className="flex items-center gap-1 font-normal text-success-800 text-sm">
          {signedWith ? (
            <FormattedMessage {...messages.signed} />
          ) : (
            <FormattedMessage {...messages.signedByEmail} />
          )}
        </p>
      </h4>
    </div>
    <div className="flex flex-col items-end">
      <p className="flex items-center gap-1 font-regular text-neutral-600 text-sm">
        <FormattedMessage
          {...messages.signedOn}
          values={{
            date: (
              <DateTime
                date={signedAt}
                dateClassName="text-neutral-600 text-sm"
                timeClassName="text-neutral-600 text-sm"
              />
            ),
          }}
        />
      </p>
      {signedWith ? (
        <WithOperator id={signedWith} />
      ) : (
        <span className="text-sm text-neutral-600">
          <span className="mr-1">
            <FormattedMessage {...messages.by} />
          </span>
          <span className="text-neutral-800 font-medium truncate">
            {`${user?.firstName} ${user?.lastName}`}
          </span>
        </span>
      )}
    </div>
    <SeeContractPDFButton
      documentId={documentId}
      contractId={contractId}
      isMigrated={isMigrated}
    />
  </Card>
);
