import { FormattedMessage } from 'react-intl';
import { Card } from '@cooltra/ui';
import {
  Contract,
  ContractAvailableAccessory,
  ContractPayment,
} from '@cooltra/station-based-api';
import { accessoryImages } from '@cooltra/utils';

import { useAccessoryName, useContractFlags } from '~/hooks';
import {
  AccessoryCounter,
  AddAccessory,
  Amount,
  ExtraAdded,
  RemoveAccessory,
} from '~/common';

import { WarningTooltip } from '../WarningTooltip/WarningTooltip';

import messages from './messages';

export type AccessoryCardProps = ContractAvailableAccessory & {
  contract: Contract;
  payments: ContractPayment[];
  quantity?: number;
  info?: string;
};

export const AccessoryCard = ({
  contract,
  payments,
  accessoryId,
  quantity = 0,
  name,
  price,
  limit,
  info,
}: AccessoryCardProps) => {
  const getAccessoryName = useAccessoryName();
  const { isSignedOrPendingSignature } = useContractFlags();

  const getIsAccessoryPaidFor = () =>
    payments
      .filter(({ status }) => status === 'PAID')
      .some(({ accessories }) =>
        accessories?.some(({ id }) => id === accessoryId)
      );

  const getIsAcessoryAwaitingPayment = () =>
    payments
      .filter(({ status }) => status === 'AWAITING_PAYMENT')
      .some(({ accessories }) =>
        accessories?.some(({ id }) => id === accessoryId)
      );

  const getCanEditAccessory = () => {
    if (isSignedOrPendingSignature) {
      return false;
    }

    if (contract.status === 'ACTIVE') {
      return false;
    }

    const contractIncludesAccessory = !!contract.accessories?.find(
      ({ id }) => id === accessoryId
    );

    if (!contractIncludesAccessory) {
      return true;
    }

    const isAccessoryAwaitingPayment = getIsAcessoryAwaitingPayment();

    if (isAccessoryAwaitingPayment) {
      return false;
    }

    const isAccessoryPaidFor = getIsAccessoryPaidFor();

    return !isAccessoryPaidFor;
  };

  const canEditAccessory = getCanEditAccessory();
  const isAccessoryPaidFor = getIsAccessoryPaidFor();
  const isAccessoryAwaitingPayment = getIsAcessoryAwaitingPayment();

  return (
    <Card data-testid={`ACCESSORY_${name}`} className="bg-neutral-0 p-8">
      <div>
        <h2 className="mb-4 text-lg font-semibold text-neutral-800">
          {getAccessoryName(name)}
        </h2>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-24 mr-6" src={accessoryImages[name]} alt={name} />
          <div className="flex flex-col">
            <span className="mb-2 text-3xl font-semibold text-neutral-800">
              <Amount {...price.total} />
            </span>
            {info ? (
              <span className="block text-neutral-500 text-sm">{info}</span>
            ) : (
              <span className="text-base font-medium text-neutral-600">
                <FormattedMessage
                  {...messages.dailyPrice}
                  values={{
                    amount: <Amount {...price.daily} />,
                  }}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end h-11">
        {limit === 1 ? (
          quantity === 1 ? (
            <div className="flex w-full justify-between gap-2 items-center">
              <div className="flex items-center gap-2">
                <RemoveAccessory
                  disabled={!canEditAccessory}
                  accessoryId={accessoryId}
                  name={name}
                />
                <WarningTooltip
                  isAccessoryPaidFor={isAccessoryPaidFor}
                  isAccessoryAwaitingPayment={isAccessoryAwaitingPayment}
                  isContractStatusActive={contract.status === 'ACTIVE'}
                />
              </div>
              <ExtraAdded />
            </div>
          ) : (
            <AddAccessory
              disabled={!canEditAccessory}
              name={name}
              accessoryId={accessoryId}
            />
          )
        ) : (
          <div className="flex items-center gap-2">
            <AccessoryCounter
              disabled={!canEditAccessory}
              accessoryId={accessoryId}
              quantity={quantity}
              limit={limit}
            />
            <WarningTooltip
              isAccessoryPaidFor={isAccessoryPaidFor}
              isAccessoryAwaitingPayment={isAccessoryAwaitingPayment}
              isContractStatusActive={contract.status === 'ACTIVE'}
            />
          </div>
        )}
      </div>
    </Card>
  );
};
