import { defineMessages } from 'react-intl';

export default defineMessages({
  channel: {
    defaultMessage: 'Channel',
  },
  entryChannel: {
    defaultMessage: 'Entry channel',
  },
});
