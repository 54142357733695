import { useIntl } from 'react-intl';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@cooltra/ui';
import {
  useContractQuery,
  useSignByEmailAnnexMutation,
} from '@cooltra/station-based-api';
import { AxiosError } from 'axios';
import { MdSend } from 'react-icons/md';

import { useNotification } from '~/hooks';

import messages from './messages';

export type SendAnnexByEmailButtonProps = {
  contractId: string;
  annexId: string;
};

export const SendAnnexByEmailButton = ({
  contractId,
  annexId,
}: SendAnnexByEmailButtonProps) => {
  const { formatMessage } = useIntl();
  const { user: operator } = useAuth0();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { mutateAsync, isPending } = useSignByEmailAnnexMutation(
    contractId,
    annexId
  );
  const { data: contract } = useContractQuery(contractId);
  const annex = contract?.annexes?.find(({ id }) => id === annexId);

  const handleClick = () =>
    mutateAsync({ operatorFullName: operator?.name ?? '' })
      .then(() => {
        addSuccessNotification(formatMessage(messages.annexSentSuccessfully));
      })
      .catch(({ response }: AxiosError) => {
        switch (response?.status) {
          default:
            addErrorNotification();
        }
      });

  return (
    <Button
      emphasis="high"
      onClick={handleClick}
      loading={isPending}
      className="w-[48px] p-0 text-center -ml-2"
      disabled={annex?.status !== 'PENDING_SIGN'}
      aria-label={formatMessage(messages.signByEmail)}
    >
      <MdSend />
    </Button>
  );
};
