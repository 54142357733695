import { IntlShape } from 'react-intl';
import { FormErrors } from '@cooltra/form';
import { Vehicle, UpdateVehiclePayload } from '@cooltra/station-based-api';

import { validateRequired } from '~/validation';

export type VehicleGeolocationFormValue = {
  latitude: number;
  longitude: number;
} | null;

export type VehicleFormValues = {
  lockCode: string;
  inTransit: boolean;
  servicePointId: string;
  geolocation: VehicleGeolocationFormValue;
  parkingPhoto: string | null;
  mileage: number | null;
  fuelLevel: number | null;
};

export const validate =
  (intl: IntlShape) =>
  (values: VehicleFormValues): FormErrors<VehicleFormValues> => {
    const errors: FormErrors<VehicleFormValues> = {};

    const servicePointIdErrorMessage = validateRequired(
      intl,
      values.servicePointId
    );
    if (servicePointIdErrorMessage) {
      errors.servicePointId = servicePointIdErrorMessage;
    } else {
      const photoErrorMessage = validateRequired(intl, values.parkingPhoto);
      if (photoErrorMessage) {
        errors.parkingPhoto = photoErrorMessage;
      }
      const locationErrorMessage = validateRequired(intl, values.geolocation);
      if (locationErrorMessage) {
        errors.geolocation = locationErrorMessage;
      }
    }
    return errors;
  };

export const getInitialValues = ({
  lockCode,
  geolocation,
  inTransit,
  servicePointId,
  parkingPhoto,
  mileage,
  fuelLevel,
}: Vehicle): VehicleFormValues => ({
  inTransit,
  lockCode: lockCode || '',
  geolocation: geolocation || null,
  servicePointId: servicePointId || '',
  parkingPhoto: parkingPhoto || null,
  mileage: mileage || null,
  fuelLevel: fuelLevel || null,
});

export const formatFormValues = ({
  lockCode,
  geolocation,
  inTransit,
  servicePointId,
  parkingPhoto,
  mileage,
  fuelLevel,
}: VehicleFormValues): UpdateVehiclePayload => ({
  inTransit,
  lockCode: lockCode || null,
  geolocation: geolocation || null,
  servicePointId,
  parkingPhoto: parkingPhoto || null,
  mileage: mileage || null,
  fuelLevel: fuelLevel || null,
});
