import { PropsWithChildren } from 'react';
import { getParams } from '@cooltra/utils';
import { FormProvider } from '@cooltra/form';
import { useLocation } from 'react-router-dom';

import { useNavigateWithQueryParams } from '~/hooks';

import {
  shiftsFiltersInitialValues,
  ShiftsFiltersFormValues,
} from './shifts-filters-form';

const { system, minDate, maxDate, operatorId, sort } =
  shiftsFiltersInitialValues;

export const useShiftsFiltersFromUrl = () => {
  const { search } = useLocation();

  const params = getParams<keyof ShiftsFiltersFormValues>(search);

  return {
    system: params.array('system').length ? params.array('system') : system,
    minDate: params.string('minDate') || minDate,
    maxDate: params.string('maxDate') || maxDate,
    operatorId: params.string('operatorId') || operatorId,
    sort: params.string('sort') || sort,
  } as ShiftsFiltersFormValues;
};

export const ShiftsFiltersForm = ({ children }: PropsWithChildren) => {
  const navigateWithQueryParams = useNavigateWithQueryParams();

  const values = useShiftsFiltersFromUrl();

  const onSubmit = async (values: ShiftsFiltersFormValues) =>
    navigateWithQueryParams(values);

  return (
    <FormProvider initialValues={values} onSubmit={onSubmit}>
      {children}
    </FormProvider>
  );
};
