import { FormattedTime } from 'react-intl';
import {
  TimelineWorkUnitClosed,
  TimelineWorkUnitItem,
  TimelineWorkUnit,
  TimelineWorkUnitStarted,
  TimelineWorkUnitTaskClosed,
} from '@cooltra/api';
import { Card } from '@cooltra/ui';
import { Fragment } from 'react';

import { Circle } from '~/common';
import { useAccordion } from '~/hooks';

import { WorkUnitTaskClosed } from '../WorkUnitTaskClosed/WorkUnitTaskClosed';
import { WorkUnitBatterySwapped } from '../WorkUnitBatterySwapped/WorkUnitBatterySwapped';
import { WorkUnitClosed } from '../WorkUnitClosed/WorkUnitClosed';
import { WorkUnitStarted } from '../WorkUnitStarted/WorkUnitStarted';

function isTimelineWorkUnitStarted(
  item: TimelineWorkUnitItem
): item is TimelineWorkUnitStarted {
  return (item as TimelineWorkUnitStarted).type === 'WORK_UNIT_STARTED';
}

function isTimelineWorkUnitClosed(
  item: TimelineWorkUnitItem
): item is TimelineWorkUnitClosed {
  return (item as TimelineWorkUnitClosed).type === 'WORK_UNIT_CLOSED';
}

function isTimelineWorkUnitTaskClosed(
  item: TimelineWorkUnitItem
): item is TimelineWorkUnitTaskClosed {
  return (item as TimelineWorkUnitTaskClosed).type === 'WORK_UNIT_TASK_CLOSED';
}

export type WorkUnitTimelineItemProps = {
  timelineUnlockOperatorAction: TimelineWorkUnit;
};

export const WorkUnitTimelineItem = ({
  timelineUnlockOperatorAction,
}: WorkUnitTimelineItemProps) => {
  const { getHeaderProps, getSectionProps, isActiveSection } = useAccordion([]);

  const index = 0;
  const id = 'work-unit-accordion-item';
  const { onClick, ...headerPropsRest } = getHeaderProps(id, index);
  const { ref: sectionRef, ...sectionPropsRest } = getSectionProps(id, index);
  const { key: headerKey, ...headerProps } = headerPropsRest;
  const { key: sectionKey, ...sectionProps } = sectionPropsRest;

  const items = timelineUnlockOperatorAction.items;
  const firstItem = items[0];

  return (
    <div className="flex items-start gap-2 py-2">
      <span className="flex items-center gap-2 text-neutral-1000 shrink-0 mt-5 pt-px">
        <span>
          <FormattedTime
            value={firstItem.createdAt}
            hour="2-digit"
            hourCycle="h23"
            minute="2-digit"
          />
        </span>
        <Circle className="bg-neutral-1000 w-2 h-2 shrink-0" />
      </span>
      <Card>
        {isTimelineWorkUnitStarted(firstItem) && (
          <WorkUnitStarted
            key={headerKey}
            operatorId={firstItem.operatorId}
            isActive={isActiveSection(0)}
            onClick={onClick}
            {...headerProps}
          />
        )}
        <div className="h-4" key={sectionKey} {...sectionProps}>
          <div ref={sectionRef} className="pl-3">
            {items.slice(1).map((item, index) => {
              const isLast = index === items.length - 2;
              if (isTimelineWorkUnitTaskClosed(item)) {
                return (
                  <WorkUnitTaskClosed
                    key={item.taskId}
                    isLast={isLast}
                    createdAt={item.createdAt}
                    taskId={item.taskId}
                    title={item.title}
                  />
                );
              }

              if (isTimelineWorkUnitClosed(item)) {
                return (
                  <Fragment key={item.createdAt}>
                    {item.batterySwapped && (
                      <WorkUnitBatterySwapped createdAt={item.createdAt} />
                    )}
                    <WorkUnitClosed
                      comment={item.comment}
                      createdAt={item.createdAt}
                      isLast={isLast}
                    />
                  </Fragment>
                );
              }
              return null;
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};
