import { defineMessages } from 'react-intl';

export default defineMessages({
  rejectWarning: {
    defaultMessage: 'This user has been rejected before. {userStateLink}',
  },
  correctionsRequestedBefore: {
    defaultMessage:
      'This user has been asked for corrections before. {userStateLink}',
  },
  historyLink: {
    defaultMessage: 'Check history',
  },
});
