import { defineMessages } from 'react-intl';

export default defineMessages({
  signByEmail: {
    defaultMessage: 'Sign by email annex',
  },
  annexSentSuccessfully: {
    defaultMessage: 'Annex sent successfully',
  },
});
