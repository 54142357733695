import { defineMessages } from 'react-intl';
import { OperationalCondition, OperationalState } from '@cooltra/api';

export const groupMessages = defineMessages<OperationalState>({
  OPERATIONAL: {
    defaultMessage: 'Operational',
  },
  NOT_OPERATIONAL: {
    defaultMessage: 'Not operational',
  },
  IN_MAINTENANCE: {
    defaultMessage: 'In maintenance',
  },
});

export const operationalConditionMessages =
  defineMessages<OperationalCondition>({
    OPERATIONAL: {
      defaultMessage: 'Operational',
    },
    BLOCKED: {
      defaultMessage: 'Blocked',
    },
    OFFLINE: {
      defaultMessage: 'Offline',
    },
    BATTERY_DISCHARGED: {
      defaultMessage: 'Battery discharged',
    },
    NOT_LOCKED: {
      defaultMessage: 'Not locked',
    },
    IN_LOCATION: {
      defaultMessage: 'In a location',
    },
    WITH_OPERATOR: {
      defaultMessage: 'Taken by operator',
    },
    WORK_IN_PROGRESS: {
      defaultMessage: 'Work in progress',
    },
    RESERVED: {
      defaultMessage: 'Reserved',
    },
    RENTED: {
      defaultMessage: 'Rented',
    },
    AVAILABLE: {
      defaultMessage: 'Available',
    },
    BATTERY_UNLOCKED: {
      defaultMessage: 'Battery unlocked',
    },
  });

export default defineMessages({
  label: {
    defaultMessage: 'Operational condition',
  },
});
