import { InputLabel, NativeSelectField } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { ChangeEvent, useState } from 'react';
import {
  SubscriptionPlan,
  useSubscriptionPlanUpdateMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';
import { allContractPlans, getContractPlanMessage } from '~/utils/contract';

import messages from './messages';

const id = 'subscription-contract-plan';

export type SubscriptionContractPlanFieldProps = {
  initialValue: SubscriptionPlan | null;
  contractId: string;
};

export const SubscriptionContractPlanField = ({
  initialValue,
  contractId,
}: SubscriptionContractPlanFieldProps) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState<SubscriptionPlan>(initialValue || 'URBAN');

  const { mutateAsync } = useSubscriptionPlanUpdateMutation(contractId);
  const { addErrorNotification } = useNotification();

  const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const plan = target.value as SubscriptionPlan;
    setValue(plan);
    mutateAsync({ plan }).catch(() => {
      setValue(initialValue || 'URBAN');
      addErrorNotification();
    });
  };

  const options = allContractPlans.map((plan) => ({
    value: plan,
    label: formatMessage(getContractPlanMessage(plan)),
  }));

  return (
    <NativeSelectField
      className="w-72"
      id={id}
      value={value}
      onChange={handleChange}
      label={
        <InputLabel htmlFor={id}>
          <FormattedMessage {...messages.plan} />
        </InputLabel>
      }
      options={options}
    />
  );
};
