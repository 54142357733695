import { Contract, ContractStatus } from '@cooltra/station-based-api';

export type ContractPath =
  | 'overview'
  | 'details'
  | 'driver'
  | 'vehicles'
  | 'extras'
  | 'payments'
  | 'checks-damages';

export const isStatusPermitted = (
  contractPath: ContractPath,
  status: ContractStatus
) => {
  const isNew = status === 'NEW';
  const isDraft = status === 'DRAFT';
  const isBooked = status === 'BOOKED';
  const isActive = status === 'ACTIVE';
  const isCancelled = status === 'CANCELED';
  const isNoShow = status === 'NO_SHOW';
  const isClosed = status === 'CLOSED';

  switch (contractPath) {
    case 'overview':
      return (
        isDraft || isBooked || isActive || isCancelled || isNoShow || isClosed
      );
    case 'details':
      return isNew || isDraft || isBooked || isActive;
    case 'driver':
      return isDraft || isBooked || isActive || isClosed || isNoShow;
    case 'vehicles':
      return isDraft || isBooked || isActive;
    case 'extras':
      return isDraft || isBooked || isActive;
    case 'payments':
      return isDraft || isBooked || isActive || isClosed || isNoShow;
    case 'checks-damages':
      return isDraft || isBooked || isActive || isClosed;
  }
};

export const isLinkEnabled = (
  contractPath: ContractPath,
  { status, vehicle }: Contract
) => {
  const isCorrectStatus = isStatusPermitted(contractPath, status);

  if (!isCorrectStatus) {
    return false;
  }

  switch (contractPath) {
    case 'overview':
      return true;
    case 'details':
      return true;
    case 'driver':
      return true;
    case 'vehicles':
      return true;
    case 'extras':
      return !!vehicle;
    case 'payments':
      return !!vehicle;
    case 'checks-damages':
      return !!vehicle?.assignedVehicle;
  }
};
