import { SelectField, InputLabel, InputError } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useField } from '@cooltra/form';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const BillingServicePointSelector = () => {
  const intl = useIntl();
  const { servicePointsOptions, isLoading } = useServicePointOptions();

  const { value, setValue, touched, error, setTouched } = useField(
    'billingServicePointId'
  );

  return (
    <div>
      <SelectField
        id="billingServicePointId"
        name="billingServicePointId"
        error={touched && error ? <InputError>{error}</InputError> : undefined}
        isLoading={isLoading}
        value={value}
        label={
          <InputLabel htmlFor="billingServicePointId" className="shrink-0">
            <FormattedMessage {...messages.currentServicePoint} />
          </InputLabel>
        }
        options={servicePointsOptions}
        placeholder={intl.formatMessage(messages.all)}
        onBlur={() => setTouched(true)}
        onChange={(selectedOption) => {
          setValue(selectedOption);
        }}
      />
    </div>
  );
};
