import { Accessories } from './Accessories';
import { Coverage } from './Coverage';
import { Deposit } from './Deposit';
import { Penalties } from './Penalties';
import { Total } from './Total';
import { Vehicle } from './Vehicle';

export const ContractPaymentItems = {
  Accessories,
  Coverage,
  Deposit,
  Penalties,
  Total,
  Vehicle,
};
