import { consecutiveNumbers } from '@cooltra/utils';

export const daySlots = consecutiveNumbers(24)
  .map((hour) =>
    ['00', '30'].map(
      (minutes) => `${hour.toString().padStart(2, '0')}:${minutes}`
    )
  )
  .flat();

export const oneDay = 1000 * 60 * 60 * 24;
