import { FormattedMessage } from 'react-intl';
import { Contract, useContractPaymentsQuery } from '@cooltra/station-based-api';

import { SubscriptionContractPendingPayments } from '../SubscriptionContractPendingPayments/SubscriptionContractPendingPayments';

import messages from './messages';

export type SubscriptionContractPaymentsProps = {
  contract: Contract;
};

export const SubscriptionContractPayments = ({
  contract,
}: SubscriptionContractPaymentsProps) => {
  const { contractId } = contract;
  const { data: payments = [] } = useContractPaymentsQuery(contractId, {
    enabled: !!contractId,
  });

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <h1 className="text-xl text-neutral-700 mb-4">
        <FormattedMessage {...messages.payments} />
      </h1>
      <SubscriptionContractPendingPayments
        contract={contract}
        payments={payments}
      />
    </div>
  );
};
