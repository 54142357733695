import { City } from './City';
import { Country } from './Country';
import { DateOfBirth } from './DateOfBirth';
import { DriverLicenseNumber } from './DriverLicenseNumber';
import { IdCardCountry } from './IdCardCountry';
import { Language } from './Language';
import { Street } from './Street';
import { Zip } from './Zip';

export const DriverFields = {
  City,
  Country,
  DateOfBirth,
  DriverLicenseNumber,
  IdCardCountry,
  Language,
  Street,
  Zip,
};
