import { Button } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import {
  Contract,
  useUnassignVehicleToContractMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

import messages from './messages';

export type UnassignLicensePlateProps = {
  contract: Contract;
  disabled?: boolean;
};

export const UnassignLicensePlate = ({
  contract: { contractId, vehicle },
  disabled = false,
}: UnassignLicensePlateProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { formatMessage } = useIntl();
  const { addErrorNotification } = useNotification();

  const { mutateAsync, isPending } =
    useUnassignVehicleToContractMutation(contractId);

  const handleOnConfirm = () =>
    mutateAsync()
      .then(() => toggleOff())
      .catch(() => addErrorNotification());

  return (
    <>
      <Button
        emphasis="low"
        variant="danger"
        onClick={toggleOn}
        disabled={disabled}
      >
        <FormattedMessage {...messages.unassignLicensePlate} />
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title={formatMessage(messages.unassignLicensePlate)}
        content={formatMessage(messages.areYouSureToUnassignLicensePlate, {
          licensePlate: vehicle?.assignedVehicle?.licensePlate,
        })}
        onClose={toggleOff}
        onConfirm={handleOnConfirm}
        loading={isPending}
      />
    </>
  );
};
