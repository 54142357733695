import { PropsWithChildren, useState } from 'react';
import { Modal } from '@cooltra/ui';

import { QRPayment } from '~/common';

import { QRPaymentContext } from './QRPaymentContext';

export const QRPaymentProvider = ({ children }: PropsWithChildren) => {
  const [{ isOpen, ticketId }, setState] = useState({
    isOpen: false,
    ticketId: '',
  });

  const openModal = (ticketId: string) =>
    setState({
      ticketId,
      isOpen: true,
    });

  const closeModal = () =>
    setState(({ ticketId }) => ({ isOpen: false, ticketId }));

  const onAfterClose = () =>
    setState(({ isOpen }) => ({ isOpen, ticketId: '' }));

  return (
    <QRPaymentContext.Provider value={{ openModal }}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        onAfterClose={onAfterClose}
      >
        <QRPayment ticketId={ticketId} className="px-20 pb-12" />
      </Modal>
      {children}
    </QRPaymentContext.Provider>
  );
};
