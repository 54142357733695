import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { InputLabel } from '@cooltra/ui';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const ServicePointIdField = () => {
  const intl = useIntl();
  const { servicePointsOptions } = useServicePointOptions();

  return (
    <FormMultiSelectField
      id="servicePointId"
      name="servicePointId"
      label={
        <InputLabel htmlFor="servicePointId" className="shrink-0">
          <FormattedMessage {...messages.servicePoint} />
        </InputLabel>
      }
      options={servicePointsOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
