import { useContext } from 'react';

import { QRPaymentContext } from './QRPaymentContext';

export const useQRPayment = () => {
  const qrPaymentContext = useContext(QRPaymentContext);
  if (!qrPaymentContext) {
    throw new Error(
      'useQRPayment can be only used within <QRPaymentProvider />'
    );
  }
  return qrPaymentContext;
};
