import { defineMessages } from 'react-intl';

export default defineMessages({
  dateTime: {
    defaultMessage: 'Date and time',
  },
  date: {
    defaultMessage: 'Date',
  },
  time: {
    defaultMessage: 'Time',
  },
});
