import { Contract } from '@cooltra/station-based-api';

import {
  ContractBadge,
  DiscardContractButton,
  SubscriptionContractState,
} from '~/common';

export type SubscriptionContractNavbarProps = {
  contract: Contract;
};

export const SubscriptionContractNavbar = ({
  contract,
}: SubscriptionContractNavbarProps) => {
  const { contractNumber, status } = contract;

  return (
    <div
      data-testid="SUBSCRIPTION_CONTRACT_NAVBAR"
      className="bg-neutral-0 shadow-sm relative z-10 h-20 px-8"
    >
      <div className="flex flex-row justify-between items-center gap-x-4 h-full">
        <div className="h-full flex flex-row items-center justify-center gap-x-4 gap-y-4">
          <h2 className="font-semibold text-2xl text-neutral-700">
            {contractNumber}
          </h2>
          <div className="flex gap-x-4 items-center">
            <ContractBadge status={status} />
            <SubscriptionContractState compact={true} />
          </div>
        </div>
        <div className="h-full flex items-center">
          <DiscardContractButton contract={contract} />
        </div>
      </div>
    </div>
  );
};
