import {
  SubscriptionAvailableVehicleType,
  usePatchSubscriptionVehicleTypesMutation,
} from '@cooltra/station-based-api';
import { Card } from '@cooltra/ui';
import { getVehicleName, vehicleImages } from '@cooltra/utils';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { AddVehicleTypeButton, Amount } from '~/common';

import messages from './messages';

export type SubscriptionContractVehicleTypeCardProps = {
  vehicle: SubscriptionAvailableVehicleType;
};

export const SubscriptionContractVehicleTypeCard = ({
  vehicle: { name, price, id },
}: SubscriptionContractVehicleTypeCardProps) => {
  const { contractId = '' } = useParams();
  const { mutateAsync } = usePatchSubscriptionVehicleTypesMutation(contractId);

  const addVehicleType = () => mutateAsync({ vehicleTypeId: id });

  return (
    <Card data-testid={`VEHICLE_${name}`} className="bg-neutral-0 p-8">
      <h2 className="mb-4 text-lg font-semibold text-neutral-800">
        {getVehicleName(name)}
      </h2>
      <div className="flex items-center">
        <img
          className="w-24 mr-6"
          alt={getVehicleName(name)}
          src={vehicleImages[name]}
        />
        <div>
          <span className="block mb-2 text-3xl font-semibold text-neutral-800">
            <Amount {...price} />
          </span>
          <span className="text-base font-medium text-neutral-600">
            <FormattedMessage {...messages.startingFrom} />
          </span>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <AddVehicleTypeButton onAdd={addVehicleType} />
      </div>
    </Card>
  );
};
