import { defineMessages } from 'react-intl';

export default defineMessages({
  conflict: {
    defaultMessage:
      'New service point does not have the same vehicle type or accesories. You must refund paid tickets and release deposits.',
  },
  delivery: {
    defaultMessage: 'Delivery',
  },
});
