import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Contract } from '@cooltra/station-based-api';

import { ContractPath, isLinkEnabled } from './utils/is-link-enabled';

export type RedirectOnDisabledProps = {
  children: ReactNode;
  contract: Contract;
  redirectPath: string;
};

export const RedirectOnDisabled = ({
  children,
  contract,
  redirectPath,
}: RedirectOnDisabledProps) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop() as ContractPath;

  if (!isLinkEnabled(currentPath, contract)) {
    return <Navigate to={redirectPath} />;
  }

  return children;
};
