import { defineMessages } from 'react-intl';

export default defineMessages({
  penaltyPriceMayVary: {
    defaultMessage:
      'Value for this penalty may vary from {minAmount} to {maxAmount}',
  },
  ifNeededAdjustOnPaymentStep: {
    defaultMessage: 'If necessary, adjust on payment step',
  },
});
