import {
  useContractQuery,
  useServicePointsQuery,
} from '@cooltra/station-based-api';
import { useParams } from 'react-router-dom';

import { isTestingEnv } from '~/utils/e2e';

import {
  isDriversDataComplete,
  isTaxIdRequired,
} from './is-drivers-data-complete';
import { areChecksInCompleted, areChecksOutCompleted } from './checks';

export const useSubscriptionContractFlags = () => {
  const { contractId = '' } = useParams();

  const { data: servicePoints } = useServicePointsQuery({
    enabled: isTestingEnv(),
  });

  const { data: contract } = useContractQuery(contractId, {
    enabled: isTestingEnv(),
  });

  if (!contract || !servicePoints) {
    return {
      pendingChecksIn: false,
      pendingChecksOut: false,
      areDetailsEditable: false,
      isTaxIdRequired: false,
      hasDriversDataWarning: false,
      hasAssignedVehicle: false,
    };
  }

  const { status, vehicle } = contract;
  const areDetailsEditable =
    status === 'DRAFT' || status === 'NEW' || status === 'BOOKED';

  const servicePointCountry = servicePoints?.find(
    ({ id }) => id === contract.details?.servicePointId
  )?.country;

  const hasDriversDataWarning =
    !isDriversDataComplete(contract, servicePointCountry) &&
    !['ACTIVE', 'CLOSED'].includes(status);

  const hasAssignedVehicle = !!contract.vehicle?.assignedVehicle;

  const isBike = !!vehicle && vehicle.category === 'EBIKE';

  const pendingChecksOut =
    status === 'ACTIVE' && !areChecksOutCompleted(contract, isBike);

  const pendingChecksIn = !areChecksInCompleted(contract, isBike);

  return {
    pendingChecksIn,
    pendingChecksOut,
    areDetailsEditable,
    isTaxIdRequired: isTaxIdRequired({
      servicePointCountry,
      taxIdCountry: contract.user?.taxIdCountry,
    }),
    hasDriversDataWarning,
    hasAssignedVehicle,
  };
};
