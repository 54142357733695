import { RentalState, ReservationState } from '@cooltra/api';
import { defineMessages } from 'react-intl';

export const rentalStateMessages = defineMessages<RentalState>({
  COMPLETED: {
    defaultMessage: 'Completed',
  },
  CLOSED: {
    defaultMessage: 'Closed',
  },
  ACTIVE: {
    defaultMessage: 'Active',
  },
});

export const reservationStateMessages = defineMessages<ReservationState>({
  ACTIVE: {
    defaultMessage: 'Active',
  },
  USED: {
    defaultMessage: 'Used',
  },
  FAILED: {
    defaultMessage: 'Failed',
  },
  CANCELED: {
    defaultMessage: 'Canceled',
  },
  EXPIRED: {
    defaultMessage: 'Expired',
  },
  CHECKING_IN: {
    defaultMessage: 'Checking-in',
  },
});
