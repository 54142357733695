import { FormattedMessage } from 'react-intl';
import { Badge } from '@cooltra/ui';
import { MdSync } from 'react-icons/md';
import { classNames } from '@cooltra/utils';

import messages from './messages';

export type SubscriptionContractStateProps = {
  active?: boolean;
  compact?: boolean;
};

export const SubscriptionContractState = ({
  active = true,
  compact = false,
}: SubscriptionContractStateProps) => {
  return (
    <Badge variant={active ? 'success' : 'danger'}>
      <span className="flex gap-1 items-center">
        <MdSync className="text-base" />
        <span className={classNames(compact && 'sr-only')}>
          <FormattedMessage {...messages.renew} />
        </span>
      </span>
    </Badge>
  );
};
