import { Navigate, Route, Routes } from 'react-router-dom';
import { useServicePointsQuery } from '@cooltra/station-based-api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { stringifyParams } from '@cooltra/utils';
import { Helmet } from 'react-helmet';
import { SpinnerScreen } from '@cooltra/ui';

import { usePreferredServicePoints } from '~/libs/preferred-service-points';
import { ErrorPage, Four0Three, QRCode } from '~/common';
import { oneDay } from '~/utils/time';

import { Vehicles } from './Vehicles/Vehicles';
import { Contracts } from './Contracts/Contracts';
import { CreateContract } from './CreateContract/CreateContract';
import { DailyView } from './DailyView/DailyView';
import { WeeklyView } from './WeeklyView/WeeklyView';
import { MonthlyView } from './MonthlyView/MontlyView';
import { Navigation } from './Navigation';
import { formatDateQueryParam } from './utils';
import { VehicleDetails } from './VehicleDetails';

export const POS = () => {
  const { isError, isLoading } = useServicePointsQuery({
    staleTime: oneDay,
  });

  const { preferredServicePoints: savedServicePointIds } =
    usePreferredServicePoints();

  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:station_based_contracts')) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (isLoading) {
    return <SpinnerScreen />;
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="h-screen-content flex">
        <Navigation />
        <div className="flex-1 h-full">
          <Routes>
            <Route path="daily" element={<DailyView />} />
            <Route path="weekly" element={<WeeklyView />} />
            <Route path="monthly" element={<MonthlyView />} />
            <Route path="contracts/*" element={<Contracts />} />
            <Route path="new-contract" element={<CreateContract />} />
            <Route path="vehicles" element={<Vehicles />} />
            <Route path="vehicles/:vehicleId" element={<VehicleDetails />} />
            <Route
              path="*"
              element={
                <Navigate
                  to={`daily?${stringifyParams({
                    date: formatDateQueryParam(new Date()),
                    servicePointId: savedServicePointIds,
                    status: ['ACTIVE', 'BOOKED', 'NO_SHOW', 'DRAFT'],
                  })}`}
                />
              }
            />
          </Routes>
        </div>
      </div>
      <QRCode />
    </>
  );
};
