import { FormattedMessage } from 'react-intl';
import { ContractDeposit } from '@cooltra/station-based-api';

import { Amount, DateTime } from '~/common';
import { getContractDepositMethodMessage } from '~/utils/contract';

import messages from './messages';

export type ReleasedDepositProps = {
  deposit: ContractDeposit;
};

export const ReleasedDeposit = ({
  deposit: { amount, operationNumber, method, releasedAt, lastFourDigits },
}: ReleasedDepositProps) => (
  <div className="flex justify-between items-stretch">
    <div className="flex flex-col gap-3">
      <span className="font-medium text-lg">
        <FormattedMessage {...messages.depositReleased} />
      </span>
      <div className="flex items-center gap-3 text-neutral-500">
        <DateTime
          date={releasedAt}
          dateClassName="text-neutral-500 text-base"
          timeClassName="text-neutral-500 text-base"
        />
        <span className="text-neutral-100"> | </span>
        <FormattedMessage
          {...getContractDepositMethodMessage(method)}
          tagName="span"
        />
        {method === 'TPV' && (
          <>
            <span className="text-neutral-100"> | </span>
            <span> #{operationNumber} </span>
            <span className="text-neutral-100">|</span>
            <span>#{lastFourDigits}</span>
          </>
        )}
      </div>
    </div>
    <div className="flex items-end">
      <Amount
        className="font-semibold text-xl text-neutral-800 grow"
        {...amount}
      />
    </div>
  </div>
);
