import { AlarmType } from '@cooltra/api';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type AlarmTypeMessageProps = {
  type: AlarmType;
};

export const AlarmTypeMessage = ({ type }: AlarmTypeMessageProps) => (
  <FormattedMessage {...messages[type]} />
);
