import { defineMessages } from 'react-intl';

export default defineMessages({
  penalties: {
    defaultMessage: 'Penalties',
  },
  reload: {
    defaultMessage: 'Reload',
  },
  error: {
    defaultMessage: 'There has been a problem loading contract penalties',
  },
  added: {
    defaultMessage: 'Added',
  },
});
