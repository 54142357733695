import { defineMessages } from 'react-intl';
import { ContractAnnexType } from '@cooltra/station-based-api';

export default defineMessages({
  annex: {
    defaultMessage: 'Annex',
  },
  sentOn: {
    defaultMessage: 'Sent on {date}',
  },
  seeAnnex: {
    defaultMessage: 'See annex',
  },
  by: {
    defaultMessage: 'by',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
});

export const sentAnnexTypesMessages = defineMessages<ContractAnnexType>({
  EXTENSION: {
    defaultMessage: 'Extension of {days} days pending signature',
  },
  CHANGE_VEHICLE: {
    defaultMessage: 'Change vehicle extension pending signature',
  },
});
