import { FormattedDate, FormattedMessage } from 'react-intl';
import { ContractDeposit, ContractStatus } from '@cooltra/station-based-api';
import { Link } from '@cooltra/ui';
import { capitalize } from '@cooltra/utils';

import { Amount, DateTime } from '~/common';
import { useContractFlags } from '~/hooks';
import { getEnvVariable } from '~/utils/environment';
import { getContractDepositMethodMessage } from '~/utils/contract';

import { ReleaseDeposit } from '../ReleaseDeposit/ReleaseDeposit';

import messages from './messages';

export type WithheldDepositProps = {
  contractId: string;
  contractStatus: ContractStatus;
  deposit: ContractDeposit;
};

const getStripeHref = (stripeId: string) => {
  const accountPath = '/acct_1ArSXbLD1f1asXZR';

  const testPath =
    getEnvVariable('ENVIRONMENT') === 'production' ? '' : '/test';

  return `https://dashboard.stripe.com${accountPath}${testPath}/customers/${stripeId}`;
};

export const WithheldDeposit = ({
  contractId,
  contractStatus,
  deposit,
}: WithheldDepositProps) => {
  const {
    withheldAt,
    amount,
    operationNumber,
    lastFourDigits,
    method,
    withheldValidUntil,
    isCharge,
    releasedAt,
    paymentMethodDetails,
  } = deposit;

  const { hasDepositBeenReleasedAutomatically } = useContractFlags();
  const expirationDate = releasedAt || withheldValidUntil;

  return (
    <div className="flex justify-between items-end">
      <div className="flex flex-col gap-3">
        <span className="font-medium text-lg">
          {isCharge ? (
            <FormattedMessage {...messages.depositCharged} />
          ) : (
            <FormattedMessage {...messages.depositRetained} />
          )}
        </span>
        {method === 'TPV' && hasDepositBeenReleasedAutomatically && (
          <span className="text-warning-600">
            <FormattedMessage {...messages.depositExpired} />
          </span>
        )}
        <div className="flex items-center gap-3 text-neutral-500">
          <DateTime
            date={
              hasDepositBeenReleasedAutomatically ? expirationDate : withheldAt
            }
            dateClassName="text-neutral-500 text-base"
            timeClassName="text-neutral-500 text-base"
          />
          <span className="text-neutral-100">|</span>
          {method === 'TPV' && (
            <>
              <FormattedMessage
                {...getContractDepositMethodMessage(method)}
                tagName="span"
              />
              <span className="text-neutral-100">|</span>
              <span>#{operationNumber}</span>
              <span className="text-neutral-100">|</span>
              <span>#{lastFourDigits}</span>
            </>
          )}
          {method === 'BOOKING_CARD' && paymentMethodDetails && (
            <Link
              href={getStripeHref(paymentMethodDetails.customerId)}
              target="_blank"
            >
              <span>
                {capitalize(paymentMethodDetails.brand).concat(
                  ' ···· ',
                  paymentMethodDetails.lastFourDigits
                )}
              </span>
            </Link>
          )}
          {method !== 'BOOKING_CARD' && method !== 'TPV' && (
            <FormattedMessage
              {...getContractDepositMethodMessage(method)}
              tagName="span"
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3 items-end">
        <div className="flex items-center gap-3">
          <ReleaseDeposit
            deposit={deposit}
            contractStatus={contractStatus}
            contractId={contractId}
          />
          <Amount
            className="font-semibold text-xl text-neutral-800"
            {...amount}
          />
        </div>
        <span className="block text-neutral-500">
          {!hasDepositBeenReleasedAutomatically && withheldValidUntil && (
            <FormattedMessage
              {...messages.retentionValidUntil}
              tagName="span"
              values={{
                date: (
                  <FormattedDate
                    value={new Date(withheldValidUntil)}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                ),
              }}
            />
          )}
        </span>
      </div>
    </div>
  );
};
