import { defineMessages } from 'react-intl';

export default defineMessages({
  heading: {
    defaultMessage: 'Latest Activities',
  },
  unlocked: {
    defaultMessage: 'Unlocked {byOperator} ',
  },
});
