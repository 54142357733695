import { FormattedMessage, FormattedTime } from 'react-intl';
import { TimelineUnlockOperatorAction } from '@cooltra/api';
import { Card, Icon } from '@cooltra/ui';
import { MdOutlineLockOpen } from 'react-icons/md';

import { ByOperator, Circle } from '~/common';

import messages from './messages';

export type TimelineUnlockOperatorActionItemProps = {
  timelineUnlockOperatorActionItem: TimelineUnlockOperatorAction;
};

export const TimelineUnlockOperatorActionItem = ({
  timelineUnlockOperatorActionItem,
}: TimelineUnlockOperatorActionItemProps) => {
  return (
    <div className="flex items-start gap-2 py-2">
      <span className="flex items-center gap-2 text-neutral-1000 shrink-0 mt-5 pt-px">
        <span>
          <FormattedTime
            value={timelineUnlockOperatorActionItem.createdAt}
            hour="2-digit"
            hourCycle="h23"
            minute="2-digit"
          />
        </span>
        <Circle className="bg-neutral-1000 w-2 h-2 shrink-0" />
      </span>
      <Card>
        <div className="flex items-center gap-2 p-5">
          <Icon className="text-neutral-400 text-xl">
            <MdOutlineLockOpen />
          </Icon>
          <span className="text-neutral-1000 font-semibold">
            <FormattedMessage
              {...messages.unlocked}
              values={{
                byOperator: (
                  <ByOperator
                    id={timelineUnlockOperatorActionItem.operatorId}
                  />
                ),
              }}
            />
          </span>
        </div>
      </Card>
    </div>
  );
};
