import { defineMessages } from 'react-intl';

export default defineMessages({
  state: {
    defaultMessage: 'State',
  },
  placeholder: {
    defaultMessage: 'All',
  },
});
