import { useFormContext } from '@cooltra/form';
import { useEffect } from 'react';
import { useNavigation } from 'react-day-picker';

import { DateTimePickerFormValues } from './types';

export const GoToStartDateMonth = () => {
  const { goToMonth } = useNavigation();
  const { initialValues } = useFormContext<DateTimePickerFormValues>();
  useEffect(() => {
    if (initialValues?.pickUpDate) {
      goToMonth(initialValues.pickUpDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.pickUpDate]);

  return null;
};
