import { MouseEvent } from 'react';
import { InputLabel, Modal, ModalProps } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormButton, FormInputField, FormProvider } from '@cooltra/form';
import {
  usePatchContractAccessoriesMutation,
  usePatchSubscriptionAccessoriesMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks/useNotification';

import messages from './messages';
import {
  AccessoryInfoFormValues,
  initialValues,
  validate,
} from './accessory-info-form';

export type AccessoryInfoModalProps = Pick<
  ModalProps,
  'isOpen' | 'onRequestClose'
> & {
  contractId: string;
  accessoryId: string;
  isSubscription?: boolean;
};

export const AccessoryInfoModal = ({
  isOpen,
  onRequestClose,
  contractId,
  accessoryId,
  isSubscription = false,
}: AccessoryInfoModalProps) => {
  const intl = useIntl();

  const { addErrorNotification } = useNotification();

  const contractMutation = usePatchContractAccessoriesMutation(contractId);
  const subscriptionMutation =
    usePatchSubscriptionAccessoriesMutation(contractId);

  const onSubmit = ({ info }: AccessoryInfoFormValues) => {
    const mutateAsync = isSubscription
      ? subscriptionMutation.mutateAsync
      : contractMutation.mutateAsync;
    return mutateAsync({ accessoryId, quantity: 1, info })
      .then(() => onRequestClose && onRequestClose({} as MouseEvent))
      .catch(() => addErrorNotification());
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="px-12">
        <h2 className="text-xl font-semibold text-neutral-800 mb-14 text-center">
          <FormattedMessage {...messages.title} />
        </h2>
      </div>
      <div className="min-w-2xl pb-12">
        <div className="max-w-md mx-auto">
          <FormProvider
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate(intl)}
          >
            <Form>
              <FormInputField
                className="mb-12"
                name="info"
                id="info"
                label={
                  <InputLabel htmlFor="info">
                    <FormattedMessage {...messages.label} />
                  </InputLabel>
                }
              />
              <div className="flex justify-center">
                <FormButton>
                  <FormattedMessage {...messages.button} />
                </FormButton>
              </div>
            </Form>
          </FormProvider>
        </div>
      </div>
    </Modal>
  );
};
