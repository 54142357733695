import { FormattedMessage, useIntl } from 'react-intl';
import { Placeholder } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';

import { NoDataAvailable } from '../../NoDataAvailable/NoDataAvailable';
import { TelematicsItemLayout } from '../TelematicsItemLayout/TelematicsItemLayout';
import { TelematicsFieldProps } from '../types';

import messages from './messages';

export const TopCase = ({ vehicleStatus, error }: TelematicsFieldProps) => {
  const { formatMessage } = useIntl();

  if (getErrorStatus(error) === 404) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.topCase)}
        value={<NoDataAvailable />}
      />
    );
  }

  if (!vehicleStatus) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.topCase)}
        value={<Placeholder className="w-20 h-2" />}
      />
    );
  }

  if (vehicleStatus.topCaseOpen === null) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.topCase)}
        value={<NoDataAvailable />}
      />
    );
  }

  const updatedAt =
    vehicleStatus.updatedAt === vehicleStatus.topCaseOpen.updatedAt
      ? undefined
      : vehicleStatus.topCaseOpen.updatedAt;

  if (!vehicleStatus.topCaseOpen.value) {
    return (
      <TelematicsItemLayout
        title={formatMessage(messages.topCase)}
        value={formatMessage(messages.closed)}
        updatedAt={updatedAt}
      />
    );
  }

  return (
    <TelematicsItemLayout
      title={formatMessage(messages.topCase)}
      value={
        <span className="text-sm text-danger-500 font-semibold block">
          <FormattedMessage {...messages.open} />
        </span>
      }
      updatedAt={updatedAt}
    />
  );
};
