import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useParams } from 'react-router-dom';
import { AccessoryName } from '@cooltra/utils';
import {
  usePatchContractAccessoriesMutation,
  usePatchSubscriptionAccessoriesMutation,
} from '@cooltra/station-based-api';

import { useNotification } from '~/hooks';

import { AccessoryInfoModal } from '../AccessoryInfoModal/AccessoryInfoModal';

import messages from './messages';

export type AddAccessoryProps = {
  disabled?: boolean;
  name: AccessoryName;
  accessoryId: string;
  isSubscription?: boolean;
};

export const AddAccessory = ({
  name,
  disabled = false,
  accessoryId,
  isSubscription = false,
}: AddAccessoryProps) => {
  const { addErrorNotification } = useNotification();
  const { contractId = '' } = useParams();
  const [isModalOpen, { toggleOn: openModal, toggleOff: closeModal }] =
    useToggle();

  const withInfoModal =
    name === 'SCOOTER_DELIVERY' || name === 'SCOOTER_PICK_UP';

  const contractMutation = usePatchContractAccessoriesMutation(contractId);

  const subscriptionMutation =
    usePatchSubscriptionAccessoriesMutation(contractId);

  const addOne = () => {
    const mutateAsync = isSubscription
      ? subscriptionMutation.mutateAsync
      : contractMutation.mutateAsync;

    mutateAsync({ accessoryId, quantity: 1 }).catch(() =>
      addErrorNotification()
    );
  };

  const isPending = isSubscription
    ? subscriptionMutation.isPending
    : contractMutation.isPending;

  return (
    <>
      <Button
        disabled={disabled}
        loading={!withInfoModal && isPending}
        onClick={withInfoModal ? openModal : addOne}
      >
        <FormattedMessage {...messages.add} />
      </Button>
      {withInfoModal && (
        <AccessoryInfoModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contractId={contractId}
          accessoryId={accessoryId}
          isSubscription={isSubscription}
        />
      )}
    </>
  );
};
