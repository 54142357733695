import { FormattedMessage } from 'react-intl';
import { ContractPayment } from '@cooltra/station-based-api';
import { getVehicleName } from '@cooltra/utils';

import { Amount } from '~/common';

import messages from './messages';

export type VehicleProps = {
  className?: string;
  payment: ContractPayment;
};

export const Vehicle = ({
  className,
  payment: { vehicleType },
}: VehicleProps) => {
  if (!vehicleType) {
    return null;
  }

  const { name, price, days } = vehicleType;

  const vehicleTypeName = getVehicleName(name);

  return (
    <div className={className}>
      <h3 className="text-sm text-neutral-800">
        <FormattedMessage {...messages.vehicle} />
      </h3>
      <div className="flex justify-between my-2 gap-8 text-neutral-800">
        <span>
          {days ? (
            <FormattedMessage
              {...messages.vehicleType}
              values={{
                vehicleTypeName: vehicleTypeName,
                contractDays: days,
              }}
            />
          ) : (
            vehicleTypeName
          )}
        </span>
        <Amount {...price} />
      </div>
    </div>
  );
};
