import { defineMessages } from 'react-intl';

export default defineMessages({
  remove: {
    defaultMessage: 'Remove',
  },
  removedSuccessfully: {
    defaultMessage: 'The penalty has been removed correctly',
  },
});
