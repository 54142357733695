import { FormInputField } from '@cooltra/form';
import { FormattedMessage } from 'react-intl';

import { OptionalFieldLabel } from '~/common';

import messages from './messages';

export const QrId = () => (
  <FormInputField
    name="qrId"
    id="qr-id"
    label={
      <OptionalFieldLabel htmlFor="qr-id">
        <FormattedMessage {...messages.qrId} />
      </OptionalFieldLabel>
    }
  />
);
