import { defineMessages } from 'react-intl';

export default defineMessages({
  createContract: {
    defaultMessage: 'New contract',
  },
  newContract: {
    defaultMessage: 'New contract',
  },
  shortTerm: {
    defaultMessage: 'Short term',
  },
  subscription: {
    defaultMessage: 'Subscription',
  },
  createNew: {
    defaultMessage: 'Create new',
  },
});
