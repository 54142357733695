import { Contract } from '@cooltra/station-based-api';

import { ContractSidebarLink } from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';

import { isLinkEnabled } from './utils/is-link-enabled';

export type SubscriptionContractSidebarProps = {
  contract: Contract;
};

export const SubscriptionContractSidebar = ({
  contract,
}: SubscriptionContractSidebarProps) => {
  const {
    hasDriversDataWarning,
    hasAssignedVehicle,
    pendingChecksIn,
    pendingChecksOut,
  } = useSubscriptionContractFlags();

  const pendingChecks = pendingChecksOut || pendingChecksIn;

  return (
    <div className="w-16 h-full flex flex-col gap-3 bg-neutral-0 z-10 shadow-md relative pt-3">
      <ContractSidebarLink
        name="overview"
        disabled={!isLinkEnabled('overview', contract)}
      />
      <ContractSidebarLink
        name="details"
        disabled={!isLinkEnabled('details', contract)}
      />
      <ContractSidebarLink
        name="driver"
        warning={hasDriversDataWarning}
        disabled={!isLinkEnabled('driver', contract)}
      />
      <ContractSidebarLink
        name="vehicles"
        warning={!hasAssignedVehicle}
        disabled={!isLinkEnabled('vehicles', contract)}
      />
      <ContractSidebarLink
        name="extras"
        disabled={!isLinkEnabled('extras', contract)}
      />
      <ContractSidebarLink
        name="payments"
        disabled={!isLinkEnabled('payments', contract)}
      />
      <ContractSidebarLink
        name="checks-damages"
        warning={pendingChecks && hasAssignedVehicle}
        disabled={!isLinkEnabled('checks-damages', contract)}
      />
    </div>
  );
};
