import { FormattedMessage } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';

import messages from './messages';

export type AddVehicleTypeButtonProps = {
  onAdd: () => Promise<void>;
};

export const AddVehicleTypeButton = ({ onAdd }: AddVehicleTypeButtonProps) => {
  const [isAdding, { toggleOn: startAdding, toggleOff: endAdding }] =
    useToggle();

  const { addErrorNotification } = useNotification();

  const addVehicleType = () => {
    startAdding();
    onAdd()
      .catch(() => addErrorNotification())
      .finally(() => endAdding());
  };

  return (
    <Button onClick={addVehicleType} loading={isAdding}>
      <FormattedMessage {...messages.add} />
    </Button>
  );
};
