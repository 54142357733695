import {
  Contract,
  ContractPayment,
  TicketPaymentMethod,
  usePayTicketContractMutation,
} from '@cooltra/station-based-api';
import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import {
  Form,
  FormButton,
  FormProvider,
  FormRadioGroupCustomField,
} from '@cooltra/form';

import { Amount, PaymentMethodOption } from '~/common';
import { useNotification } from '~/hooks';

import messages from './messages';

export type PaySubscriptionTicketProps = {
  payment: ContractPayment;
  contract: Contract;
};

export const PaySubscriptionTicket = ({
  payment,
  contract: { contractId, hasLinkedPaymentMethod, pendingAmount, details },
}: PaySubscriptionTicketProps) => {
  const [isModalOpen, { toggleOff, toggleOn }] = useToggle();
  const { addErrorNotification } = useNotification();
  const { mutateAsync, isPending } = usePayTicketContractMutation(
    contractId,
    payment.id
  );
  const initialPaymentMethod: TicketPaymentMethod = hasLinkedPaymentMethod
    ? 'CARD'
    : 'STRIPE_QR';
  const handleOnSubmit = async (values: {
    paymentMethod: TicketPaymentMethod;
  }) => {
    if (values.paymentMethod === 'CARD') {
      return mutateAsync({
        paymentMethod: values.paymentMethod,
        billingServicePointId: details!.servicePointId,
        operationNumber: null,
        lastFourDigits: null,
      }).catch(() => {
        addErrorNotification();
      });
    }
  };
  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={toggleOff}>
        <FormProvider
          initialValues={{ paymentMethod: initialPaymentMethod }}
          onSubmit={handleOnSubmit}
        >
          <Form>
            <div className="min-w-xl px-24 pb-12 flex flex-col gap-2 items-center">
              <FormRadioGroupCustomField
                className="w-full"
                id="payment-method"
                name="paymentMethod"
                legend={
                  <legend className="block text-xl font-semibold text-neutral-700 mb-8 text-center">
                    <FormattedMessage {...messages.legend} />
                  </legend>
                }
                items={(hasLinkedPaymentMethod
                  ? ['CARD', 'STRIPE_QR', 'EMAIL']
                  : ['STRIPE_QR', 'EMAIL']
                ).map((method) => ({
                  label: '',
                  value: method,
                }))}
                renderRadio={({ checked, value }) => (
                  <PaymentMethodOption
                    type={value as TicketPaymentMethod}
                    checked={checked}
                  />
                )}
              />
              <FormButton className="mt-8" disablePristine={false}>
                <FormattedMessage
                  {...messages.sendPayment}
                  values={{
                    amount: <Amount {...pendingAmount} />,
                  }}
                />
              </FormButton>
            </div>
          </Form>
        </FormProvider>
      </Modal>
      <Button
        emphasis="high"
        onClick={toggleOn}
        disabled={isPending}
        className="self-end"
      >
        <FormattedMessage {...messages.payTicket} />
      </Button>
    </>
  );
};
