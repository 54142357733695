import { SubscriptionPlan } from '@cooltra/station-based-api';

import messages from './messages';

export const getContractPlanMessage = (plan: SubscriptionPlan) =>
  messages[plan];

export const allContractPlans: SubscriptionPlan[] = [
  'URBAN',
  'INTERMEDIATE',
  'EXTRA_URBAN',
];
