import { getContractTypeName } from '~/utils/contract';

export type ContractTypeLabelProps = {
  type: string;
};

export const ContractTypeLabel = ({ type }: ContractTypeLabelProps) => (
  <div className="absolute top-0 right-0 bg-neutral-100/20 rounded-bl-lg py-3 px-5">
    <span className="text-sm font-semibold text-neutral-500">
      {getContractTypeName(type)}
    </span>
  </div>
);
