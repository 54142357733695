import { Button } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { AccessoryName } from '@cooltra/utils';
import {
  usePatchContractAccessoriesMutation,
  usePatchSubscriptionAccessoriesMutation,
} from '@cooltra/station-based-api';
import { useToggle } from '@cooltra/hooks';

import { useNotification } from '~/hooks';

import { SecondDriverWarningModal } from '../SecondDriverWarningModal/SecondDriverWarningModal';

import messages from './messages';

export type RemoveAccessoryProps = {
  disabled?: boolean;
  accessoryId: string;
  name: AccessoryName;
  isSubscription?: boolean;
};

export const RemoveAccessory = ({
  disabled = false,
  accessoryId,
  name,
  isSubscription = false,
}: RemoveAccessoryProps) => {
  const { contractId = '' } = useParams();
  const { addErrorNotification } = useNotification();

  const [isModalOpen, { toggleOn: openModal, toggleOff: closeModal }] =
    useToggle();

  const contractMutation = usePatchContractAccessoriesMutation(contractId);

  const subscriptionMutation =
    usePatchSubscriptionAccessoriesMutation(contractId);

  const removeOne = () => {
    const mutateAsync = isSubscription
      ? subscriptionMutation.mutateAsync
      : contractMutation.mutateAsync;

    mutateAsync({ accessoryId, quantity: 0 }).catch(() =>
      addErrorNotification()
    );
  };

  const isPending = isSubscription
    ? subscriptionMutation.isPending
    : contractMutation.isPending;

  const handleRemoveOne = () => {
    if (name === 'CONDUCTOR_ADICIONAL') {
      return openModal();
    }
    removeOne();
  };

  return (
    <>
      <SecondDriverWarningModal
        isOpen={isModalOpen}
        onConfirm={removeOne}
        onRequestClose={closeModal}
        isPending={isPending}
      />
      <Button
        variant="danger"
        emphasis="low"
        onClick={handleRemoveOne}
        disabled={disabled}
        loading={isPending}
      >
        <FormattedMessage {...messages.remove} />
      </Button>
    </>
  );
};
