import { SubscriptionPlan } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages<SubscriptionPlan>({
  URBAN: {
    defaultMessage: 'Urban - 500km/month',
  },
  INTERMEDIATE: {
    defaultMessage: 'Intermediate - 1000km/month',
  },
  EXTRA_URBAN: {
    defaultMessage: 'Extra Urban - 1500km/month',
  },
});
