import {
  RentalListItem,
  RentalsSort,
  useRentalsQuery,
  useRentalsTotal,
} from '@cooltra/api';
import { Form, useFormContext } from '@cooltra/form';
import { Card, InfiniteList } from '@cooltra/ui';

import {
  ApplyFiltersButton,
  ErrorPage,
  FilterFields,
  NoSearchResults,
  RentalsTotal,
  ResetFiltersButton,
} from '~/common';
import { usePreferredSystem } from '~/libs/preferred-system';
import { useNavigateWithQueryParams } from '~/hooks';

import * as RentalsFilterFields from './RentalsFilterFields';
import { RentalsListHeader } from './RentalsListHeader';
import { RentalCard, RentalCardLoading } from './RentalCard';
import {
  initialRentalsFiltersFormValues,
  RentalsFiltersFormValues,
} from './rentals-filters-form';
import { useRentalFiltersFromUrl } from './RentalsFiltersForm';

export const RentalsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const rentalFiltersFromUrl = useRentalFiltersFromUrl();
  const { values } = useFormContext<RentalsFiltersFormValues>();
  const { system } = usePreferredSystem();
  const { isError, isLoading, data, fetchNextPage, isFetchingNextPage } =
    useRentalsQuery(rentalFiltersFromUrl, {
      refetchOnWindowFocus: false,
    });

  const initialValues = {
    ...initialRentalsFiltersFormValues,
    system: [system],
  };

  const applySortFilter = (sort: string) =>
    navigateWithQueryParams({ ...values, sort: sort as RentalsSort });

  const resetFilters = () => navigateWithQueryParams(initialValues);

  const rentals = data?.pages
    ? ([] as RentalListItem[]).concat.apply([], data.pages)
    : [];

  const rentalsTotal = useRentalsTotal(rentalFiltersFromUrl) || 0;

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div>
      <Form>
        <Card className="p-8 mb-10" data-testid="RENTALS_FILTERS">
          <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
            <FilterFields.System />
            <RentalsFilterFields.State />
            <FilterFields.Created />
          </div>
          <div className="flex justify-between">
            <ResetFiltersButton
              onClick={resetFilters}
              initialValues={initialValues}
            />
            <ApplyFiltersButton />
          </div>
        </Card>
        <div className="flex justify-between items-center mb-12">
          <RentalsTotal loading={isLoading} total={rentalsTotal} />
          <RentalsFilterFields.Sort onChange={applySortFilter} />
        </div>
      </Form>
      <div data-testid="RENTALS">
        <InfiniteList
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          fetchMore={fetchNextPage}
          idProp="rentalId"
          data={rentals}
          renderEmptyMessage={<NoSearchResults />}
          renderHeader={<RentalsListHeader />}
          renderLoadingRow={<RentalCardLoading />}
          renderRow={(rental: RentalListItem) => <RentalCard {...rental} />}
        />
      </div>
    </div>
  );
};
