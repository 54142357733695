import {
  Contract,
  usePatchContractDetailsMutation,
} from '@cooltra/station-based-api';
import { getErrorStatus } from '@cooltra/axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, FormHelpers, FormProvider } from '@cooltra/form';
import { Card } from '@cooltra/ui';
import { format, parse } from 'date-fns';

import { ContractChannel } from '~/common';
import { useNotification, useSubscriptionContractFlags } from '~/hooks';
import { allContractTypes, getContractTypeName } from '~/utils/contract';
import { usePreferredServicePoints } from '~/libs/preferred-service-points';
import { dateFormat } from '~/utils/date';

import {
  getInitialSubscriptionContractDetailsFormValues,
  SubscriptionContractDetailsFormValues,
  validateSubscriptionContractDetailsForm,
} from '../subscription-contract-details-form';
import { SaveSubscriptionContractDetails } from '../SaveSubscriptionContractDetails/SaveSubscriptionContractDetails';
import { SubscriptionDeliveryDateTime } from '../SubscriptionDeliveryDateTime/SubscriptionDeliveryDateTime';
import { SubscriptionDeliveryServicePoint } from '../SubscriptionDeliveryServicePoint/SubscriptionDeliveryServicePoint';

import messages from './messages';

export type SubscriptionContractDetailsProps = {
  contract: Contract;
};

export const SubscriptionContractDetails = ({
  contract,
}: SubscriptionContractDetailsProps) => {
  const intl = useIntl();

  const { contractId, status } = contract;

  const { mutateAsync, isSuccess } =
    usePatchContractDetailsMutation(contractId);

  const { addErrorNotification } = useNotification();

  const { preferredServicePoints: savedServicePoints } =
    usePreferredServicePoints();

  const handleSubmit = (
    values: SubscriptionContractDetailsFormValues,
    { resetForm }: FormHelpers<SubscriptionContractDetailsFormValues>
  ) => {
    const typeKey =
      allContractTypes.find(
        (contractType) => getContractTypeName(contractType) === values.type
      ) || values.type;

    return mutateAsync({
      type: typeKey,
      servicePointId: values.pickup,
      startDate: format(
        parse(values.pickUpDate, dateFormat, new Date()),
        'yyyy-MM-dd'
      ),
      startTime: values.pickUpTime,
    })
      .then(() => resetForm(values))
      .catch((e) =>
        addErrorNotification(
          getErrorStatus(e) == 409
            ? intl.formatMessage(messages.conflict)
            : undefined
        )
      );
  };

  const { areDetailsEditable } = useSubscriptionContractFlags();

  const disabled = !areDetailsEditable;

  return (
    <div data-testid="SUBSCRIPTION_CONTRACT_DETAILS">
      <FormProvider
        initialValues={getInitialSubscriptionContractDetailsFormValues(
          contract,
          savedServicePoints.length == 1 ? savedServicePoints[0] : undefined
        )}
        onSubmit={handleSubmit}
        validate={validateSubscriptionContractDetailsForm(intl)}
        disabled={disabled}
      >
        <Form>
          <div className="mx-auto max-w-4xl pt-4 pb-10 flex flex-col gap-14">
            <ContractChannel />
            <div className="w-full pb-14">
              <h2 className="text-xl text-neutral-700 mb-4 ">
                <FormattedMessage {...messages.delivery} />
              </h2>
              <Card className="pt-8 pb-14 px-14 flex gap-12">
                <div className="max-w-xs flex-1">
                  <SubscriptionDeliveryServicePoint />
                </div>
                <SubscriptionDeliveryDateTime />
              </Card>
            </div>
          </div>
          <SaveSubscriptionContractDetails
            contractStatus={status}
            isSuccess={isSuccess}
          />
        </Form>
      </FormProvider>
    </div>
  );
};
