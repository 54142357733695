import { Divider } from '@cooltra/ui';
import { FormattedDate } from 'react-intl';

export type TimelineDateSeparatorProps = {
  date: string;
};

export const TimelineDateSeparator = ({ date }: TimelineDateSeparatorProps) => {
  return (
    <div className="flex items-center justify-center relative my-4">
      <Divider className="absolute" />
      <span className="relative inline-block px-8 bg-neutral-50 text-xs text-neutral-300 font-semibold">
        <FormattedDate
          value={date}
          day="2-digit"
          month="short"
          year="numeric"
        />
      </span>
    </div>
  );
};
