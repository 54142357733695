import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    defaultMessage: 'Payment method',
  },
  confirmPayment: {
    defaultMessage: 'I confirm that the client has paid',
  },
  operationNumber: {
    defaultMessage: 'Operation number',
  },
  lastFourDigits: {
    defaultMessage: 'Last 4 digits of card',
  },
  servicePointWarning: {
    defaultMessage: 'This charge will be assigned to {servicePoint}',
  },
});
