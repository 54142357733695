import { FormattedMessage } from 'react-intl';
import { Contract, ContractPayment } from '@cooltra/station-based-api';

import { ContractPendingSignAlert } from '~/common';
import { QRPaymentProvider } from '~/libs/qr-payment';

import { ContractDeposit } from '../ContractDeposit/ContractDeposit/ContractDeposit';
import { ContractPendingPayments } from '../ContractPendingPayments/ContractPendingPayments';
import { ContractPaidOrRefundedPayments } from '../ContractPaidOrRefundedPayments/ContractPaidOrRefundedPayments';

import messages from './messages';

export type ContractPaymentsProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const ContractPayments = ({
  contract,
  payments,
}: ContractPaymentsProps) => (
  <QRPaymentProvider>
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <ContractPendingSignAlert />
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-xl text-neutral-700">
          <FormattedMessage {...messages.payments} />
        </h1>
      </div>
      <ContractDeposit contract={contract} />
      <ContractPendingPayments
        contract={contract}
        contratPaymentTypes={payments}
      />
      <ContractPaidOrRefundedPayments
        contract={contract}
        contratPaymentTypes={payments}
      />
    </div>
  </QRPaymentProvider>
);
