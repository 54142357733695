import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';
import { ContractStatus } from '@cooltra/station-based-api';

import { DateTimePickerFormValues } from '../types';

import messages from './messages';

export type ClearDatesProps = {
  contractStatus: ContractStatus;
};

export const ClearDates = ({ contractStatus }: ClearDatesProps) => {
  const { setValues } = useFormContext<DateTimePickerFormValues>();

  const handleClearingDates = () =>
    setValues({
      dropOffDate: undefined,
      pickUpDate: undefined,
      pickUpTime: '',
      dropOffTime: '',
    });

  if (contractStatus === 'ACTIVE') {
    return null;
  }

  return (
    <button
      type="button"
      className="link-primary focus:underline hover:underline text-sm"
      onClick={handleClearingDates}
    >
      <FormattedMessage {...messages.clearDates} />
    </button>
  );
};
