import { ButtonHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { Image, Placeholder } from '@cooltra/ui';
import { useDamageImage } from '@cooltra/station-based-api';

import { useRotation } from '~/hooks';

import { PhotoModal } from '../../../Photo';

import messages from './messages';

export type DamagePhotoProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  name: string;
};

export const DamagePhoto = ({ name, ...rest }: DamagePhotoProps) => {
  const fetchedImageData = useDamageImage(name);

  const { formatMessage } = useIntl();

  const [isOpen, { toggleOff, toggleOn }] = useToggle();

  const { rotationClassName, rotateLeft, rotateRight } = useRotation();

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  return (
    <>
      <button
        onClick={toggleOn}
        aria-label={formatMessage(messages.fullScreen)}
        {...rest}
      >
        <Image
          src={imageData}
          className="object-cover h-full w-full"
          alt=""
          renderPlaceholder={
            <Placeholder className="h-full w-full rounded-none" />
          }
        />
      </button>
      <PhotoModal
        isOpen={isOpen}
        onRequestClose={toggleOff}
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        className={rotationClassName}
        imageData={imageData}
      />
    </>
  );
};
