import { FormattedMessage } from 'react-intl';
import { InputLabel } from '@cooltra/ui';
import { FormNativeSelectField, useFormContext } from '@cooltra/form';

import { daySlots } from '~/utils/time';

import { exceedsMaxRentalDuration } from '../utils';
import { DateTimePickerFormValues } from '../types';

import messages from './messages';
export const ReturnTime = () => {
  const {
    values: { pickUpDate, dropOffDate },
  } = useFormContext<DateTimePickerFormValues>();

  if (!dropOffDate || !exceedsMaxRentalDuration(pickUpDate, dropOffDate)) {
    return null;
  }

  return (
    <FormNativeSelectField
      className="w-40"
      options={[
        { label: '', value: '', disabled: true },
        ...daySlots.map((option) => ({
          value: option,
          label: option,
        })),
      ]}
      name="dropOffTime"
      id="return-time"
      label={
        <InputLabel htmlFor="return-time">
          <FormattedMessage {...messages.label} />
        </InputLabel>
      }
    />
  );
};
