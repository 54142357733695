import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicle: {
    defaultMessage: 'Vehicle',
  },
  vehicleNotFoundTitle: {
    defaultMessage: 'There are no vehicle types available',
  },
  vehicleNotFoundContent: {
    defaultMessage:
      'We did not find any vehicle type for the given service point and dates',
  },
});
