import { Contract, ContractPayment } from '@cooltra/station-based-api';
import { Card } from '@cooltra/ui';
import { MdError } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import { ContractPaymentItems } from '~/common';

import { PaySubscriptionTicket } from '../PaySubscriptionTicket/PaySubscriptionTicket';

import messages from './messages';

export type SubscriptionContractPendingPaymentsProps = {
  contract: Contract;
  payments: ContractPayment[];
};

export const SubscriptionContractPendingPayments = ({
  payments,
  contract,
}: SubscriptionContractPendingPaymentsProps) => {
  const { pendingAmount } = contract;
  const pendingPayment = payments.find(({ status }) => status === 'PENDING');

  return (
    <Card className="py-8 px-14 mb-4">
      {pendingPayment ? (
        <h2 className="text-lg text-neutral-800 mb-8">
          <span className="flex items-center gap-2">
            <FormattedMessage {...messages.pending} />
            {pendingAmount.value > 0 && (
              <MdError className="text-warning-500 text-xl" />
            )}
          </span>
        </h2>
      ) : (
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg text-neutral-800">
            <FormattedMessage {...messages.pending} />
          </h2>
          <span>-</span>
        </div>
      )}
      {pendingPayment && (
        <>
          <div>
            <ContractPaymentItems.Vehicle
              className="mb-2"
              payment={pendingPayment}
            />
            <ContractPaymentItems.Coverage
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayment}
            />
            <ContractPaymentItems.Accessories
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayment}
            />
            <ContractPaymentItems.Penalties
              className="mb-2 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayment}
            />
            <ContractPaymentItems.Total
              className="mb-10 mt-6 pt-6 border-t border-neutral-100"
              payment={pendingPayment}
            />
          </div>
          <div className="flex flex-row justify-end">
            <PaySubscriptionTicket
              contract={contract}
              payment={pendingPayment}
            />
          </div>
        </>
      )}
    </Card>
  );
};
