import { CheckboxField, InputLabel, RadioGroupCustomField } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { Money } from '@cooltra/utils';
import { useMemo } from 'react';
import { FormInputField, useFormContext } from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { TicketPaymentMethod } from '@cooltra/station-based-api';

import { PaymentMethodOption } from '~/common';

import { PayTicketFormValues } from '../PayTicketFormValues';
import { BillingServicePointSelector } from '../../BillingServicePointSelector/BillingServicePointSelector';

import messages from './messages';
import { SubmitButton } from './SubmitButton/SubmitButton';

export type PaymentMethodOption = {
  label: string;
  value: TicketPaymentMethod;
};

export type PayTicketFormProps = {
  hasLinkedPaymentMethod: boolean;
  amount: Money;
  operationNumber?: string | null;
  lastFourDigits?: string | null;
  isLoading: boolean;
  closeModal: VoidFunction;
  contractId: string;
};

const paymentMethods: TicketPaymentMethod[] = ['TPV', 'CASH', 'STRIPE_QR'];
const paymentMethodWithWeb: TicketPaymentMethod[] = ['CARD', 'TPV', 'CASH'];

export const PayTicketForm = ({
  hasLinkedPaymentMethod,
  amount,
  isLoading,
  closeModal,
  contractId,
}: PayTicketFormProps) => {
  const [isConfirmed, { toggle, toggleOff }] = useToggle();
  const {
    setValues,
    values: {
      paymentMethod,
      lastFourDigits,
      operationNumber,
      billingServicePointId,
    },
  } = useFormContext<PayTicketFormValues>();

  const paymentMethodOptions = hasLinkedPaymentMethod
    ? paymentMethodWithWeb
    : paymentMethods;

  const onChangePaymentMethod = (value: string) => {
    toggleOff();
    setValues({ paymentMethod: value as TicketPaymentMethod });
  };

  const isButtonDisabled = useMemo(() => {
    if (paymentMethod === 'CARD') {
      return false;
    }

    if (!billingServicePointId) {
      return true;
    }

    return (
      (!isConfirmed && paymentMethod === 'CASH') ||
      ((!operationNumber || !lastFourDigits) && paymentMethod === 'TPV')
    );
  }, [
    paymentMethod,
    isConfirmed,
    operationNumber,
    lastFourDigits,
    billingServicePointId,
  ]);

  return (
    <div className="min-w-xl px-24 pb-12 flex flex-col gap-2 items-center">
      <RadioGroupCustomField
        className="w-full"
        id="deposit-method"
        legend={
          <label
            htmlFor="deposit-method"
            className="block text-xl font-semibold text-neutral-700 mb-8 text-center"
          >
            <FormattedMessage {...messages.title} />
          </label>
        }
        items={paymentMethodOptions.map((method) => ({
          label: '',
          value: method,
        }))}
        renderRadio={({ checked, value }) => (
          <PaymentMethodOption
            type={value as TicketPaymentMethod}
            checked={checked}
          />
        )}
        onChange={onChangePaymentMethod}
        value={paymentMethod}
      />

      {paymentMethod === 'CASH' && (
        <CheckboxField
          id="confirm-cash-payment"
          checked={isConfirmed}
          label={
            <InputLabel emphasis="low" htmlFor="confirm-cash-payment">
              <FormattedMessage {...messages.confirmPayment} />
            </InputLabel>
          }
          onChange={toggle}
          className="mt-9 self-start"
        />
      )}
      {paymentMethod === 'TPV' && (
        <>
          <FormInputField
            id="operationNumber"
            name="operationNumber"
            className="mt-8 w-full"
            label={
              <InputLabel htmlFor="operationNumber">
                <FormattedMessage {...messages.operationNumber} />
              </InputLabel>
            }
          />
          <FormInputField
            id="lastFourDigits"
            name="lastFourDigits"
            className="mt-4 w-full"
            label={
              <InputLabel htmlFor="lastFourDigits">
                <FormattedMessage {...messages.lastFourDigits} />
              </InputLabel>
            }
          />
        </>
      )}
      <div className="pt-4 w-full">
        <BillingServicePointSelector />
      </div>

      <SubmitButton
        contractId={contractId}
        disabled={isButtonDisabled}
        isLoading={isLoading}
        amount={amount}
        closeModal={closeModal}
      />
    </div>
  );
};
