import { useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';

import { useServicePointOptions } from '~/hooks';

import messages from './messages';

export const ServicePointIdField = () => {
  const intl = useIntl();
  const { servicePointsOptions } = useServicePointOptions();

  return (
    <FormMultiSelectField
      id="servicePointId"
      name="servicePointId"
      aria-label={intl.formatMessage(messages.servicePoint)}
      options={servicePointsOptions}
      placeholder={intl.formatMessage(messages.all)}
    />
  );
};
