import { TicketPaymentMethod } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages({
  invoicePdf: {
    defaultMessage: 'Invoice PDF',
  },
  creditNotePdf: {
    defaultMessage: 'Credit note PDF',
  },
  pdfDoesNotExist: {
    defaultMessage: 'The PDF is not available for this invoice',
  },
  somethingWentWrongOnDownloadingPdf: {
    defaultMessage: 'Something went wrong trying to download the PDF',
  },
});

export const contractPaymentMessages = defineMessages<TicketPaymentMethod>({
  TPV: {
    defaultMessage: 'TPV',
  },
  CARD: {
    defaultMessage: 'Stripe card',
  },
  CASH: {
    defaultMessage: 'Cash',
  },
  STRIPE_QR: {
    defaultMessage: 'Charge ticket with Stripe QR',
  },
  EMAIL: {
    defaultMessage: 'Send payment link by email',
  },
});

export const contractPaymentTitleMessages = defineMessages<TicketPaymentMethod>(
  {
    TPV: {
      defaultMessage: 'In-store collection',
    },
    CARD: {
      defaultMessage: 'Web booking',
    },
    CASH: {
      defaultMessage: 'In-store collection',
    },
    STRIPE_QR: {
      defaultMessage: 'In-store collection',
    },
    EMAIL: {
      defaultMessage: 'Email',
    },
  }
);
