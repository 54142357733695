import { Legend } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormNativeSelectField } from '@cooltra/form';

import { FormDateInputField } from '~/common';
import { daySlots } from '~/utils/time';

import messages from './messages';

export const SubscriptionDeliveryDateTime = () => {
  const { formatMessage } = useIntl();

  return (
    <fieldset className="flex gap-2" data-testid="DELIVERY_DATE_TIME">
      <Legend className="mb-1">
        <FormattedMessage {...messages.dateTime} />
      </Legend>
      <FormDateInputField
        className="w-32"
        name="pickUpDate"
        aria-label={formatMessage(messages.date)}
      />
      <FormNativeSelectField
        className="w-24"
        aria-label={formatMessage(messages.time)}
        options={[
          { label: '', value: '', disabled: true },
          ...daySlots.map((option) => ({
            value: option,
            label: option,
          })),
        ]}
        name="pickUpTime"
      />
    </fieldset>
  );
};
