import {
  VehicleDamage,
  useDeleteVehicleDamageMutation,
} from '@cooltra/station-based-api';
import { Button, Card } from '@cooltra/ui';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useToggle } from '@cooltra/hooks';

import { getDamageTypeMessage, getVehiclePartMessage } from '~/utils/damages';
import { useNotification } from '~/hooks';

import { DeleteModal } from '../../../DeleteModal/DeleteModal';
import { DamagePhoto } from '../DamagePhoto/DamagePhoto';

import messages from './messages';

export type DamageCardProps = VehicleDamage & {
  vehicleId: string;
  disabled?: boolean;
};

export const DamageCard = ({
  vehicleId,
  damageId,
  createdAt,
  imageId,
  type,
  vehiclePart,
  disabled = false,
}: DamageCardProps) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { addErrorNotification } = useNotification();

  const { mutateAsync, isPending } = useDeleteVehicleDamageMutation(
    vehicleId,
    damageId
  );

  const handleDelete = () =>
    mutateAsync()
      .then(() => toggleOff())
      .catch(() => addErrorNotification());

  return (
    <Card className="flex overflow-hidden h-48">
      <div className="shrink-0 flex">
        <DamagePhoto className="w-48 h-full" name={imageId} />
      </div>
      <div className="flex-1 p-6 flex flex-col justify-between h-full items-start">
        <div>
          <p className="text-neutral-800 font-semibold text-lg mb-2.5">
            <FormattedMessage {...getDamageTypeMessage(type)} />
            {', '}
            <FormattedMessage {...getVehiclePartMessage(vehiclePart)} />
          </p>
          <span className="text-neutral-500 text-sm block">
            <FormattedDate
              value={new Date(createdAt)}
              day="2-digit"
              month="short"
              year="numeric"
            />
          </span>
        </div>
        <Button
          onClick={toggleOn}
          variant="danger"
          size="sm"
          disabled={disabled}
        >
          <FormattedMessage {...messages.delete} />
        </Button>
        <DeleteModal
          onClose={toggleOff}
          onConfirm={handleDelete}
          isOpen={isOpen}
          isDeleting={isPending}
        />
      </div>
    </Card>
  );
};
