import { FormattedMessage } from 'react-intl';
import { TimelineItem, useVehicleTimelineQuery } from '@cooltra/api';
import { format } from 'date-fns';

import messages from './messages';
import { WorkUnitTimelineItem } from './WorkUnitTimelineItem';
import { TimelineUnlockOperatorActionItem } from './TimelineUnlockOperatorActionItem';
import { TimelineDateSeparator } from './TimelineDateSeparator';

export type VehicleTimelineProps = {
  vehicleId: string;
};

type GroupRecord = Record<string, boolean>;

export const VehicleTimeline = ({ vehicleId }: VehicleTimelineProps) => {
  const { data } = useVehicleTimelineQuery(vehicleId);

  const getGroups = (data: TimelineItem[]) => {
    let currentDate = '';
    const grouped: GroupRecord = {};

    data.map((timelineItem) => {
      const newDay = format(timelineItem.createdAt, 'dd-MM');
      const mustBeGrouped = newDay !== currentDate;
      grouped[timelineItem.createdAt] = mustBeGrouped;

      if (mustBeGrouped) {
        currentDate = newDay;
      }
    });

    return grouped;
  };

  if (!data || !data.length) {
    return null;
  }

  const grouped = getGroups(data);

  return (
    <div data-testid="VEHICLE_TIMELINE">
      <h2 className="text-base text-neutral-500 font-semibold mb-6">
        <FormattedMessage {...messages.heading} />
      </h2>
      {data.map((timelineItem, unitIndex) => (
        <div key={`container-${unitIndex}`}>
          {grouped[timelineItem.createdAt] && (
            <TimelineDateSeparator
              date={timelineItem.createdAt}
              key={`timedate-separator-${unitIndex}`}
            />
          )}
          {timelineItem.type == 'WORK_UNIT' ? (
            <WorkUnitTimelineItem
              key={`work-unit-${unitIndex}`}
              timelineUnlockOperatorAction={timelineItem}
            />
          ) : (
            <TimelineUnlockOperatorActionItem
              key={`unlock-${unitIndex}`}
              timelineUnlockOperatorActionItem={timelineItem}
            />
          )}
        </div>
      ))}
    </div>
  );
};
