import { TicketPaymentMethod } from '@cooltra/station-based-api';
import { classNames } from '@cooltra/utils';
import { ReactNode } from 'react';
import {
  MdBackupTable,
  MdCreditCard,
  MdPayments,
  MdQrCode,
  MdSend,
} from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const methodIcons: Record<TicketPaymentMethod, ReactNode> = {
  TPV: <MdBackupTable className="text-2xl" />,
  CARD: <MdCreditCard className="text-2xl" />,
  CASH: <MdPayments className="text-2xl" />,
  STRIPE_QR: <MdQrCode className="text-2xl" />,
  EMAIL: <MdSend className="text-2xl" />,
};

export type PaymentMethodOptionProps = {
  type: TicketPaymentMethod;
  checked: boolean;
};

export const PaymentMethodOption = ({
  type,
  checked,
}: PaymentMethodOptionProps) => (
  <div
    className={classNames(
      'p-6 flex gap-2 w-full font-semibold transition-colors',
      checked && 'text-primary-500'
    )}
  >
    {methodIcons[type]}
    <FormattedMessage {...messages[type]} tagName="span" />
  </div>
);
