import { Button } from '@cooltra/ui';
import {
  Contract,
  useDiscardContractMutation,
} from '@cooltra/station-based-api';
import { FormattedMessage, useIntl } from 'react-intl';
import { useToggle } from '@cooltra/hooks';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '~/hooks/useNotification';

import { ConfirmModal } from '../../ConfirmModal/ConfirmModal';

import messages from './messages';

export type DiscardContractButtonProps = {
  contract: Contract;
};

export const DiscardContractButton = ({
  contract: { contractId, status },
}: DiscardContractButtonProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [isOpen, { toggleOn, toggleOff }] = useToggle();
  const { mutateAsync, isPending } = useDiscardContractMutation(contractId);
  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleConfirm = () =>
    mutateAsync()
      .then(() => {
        toggleOff();
        addSuccessNotification(intl.formatMessage(messages.success));
        navigate('/pos');
      })
      .catch(() => {
        toggleOff();
        addErrorNotification();
      });

  if (status !== 'DRAFT') {
    return null;
  }

  return (
    <>
      <ConfirmModal
        dataTestId="DISCARD_CONTRACT_CONFIRM_MODAL"
        isOpen={isOpen}
        title={intl.formatMessage(messages.confirmTitle)}
        content={intl.formatMessage(messages.confirmContent)}
        onClose={toggleOff}
        onConfirm={handleConfirm}
        loading={isPending}
      />
      <Button variant="neutral" onClick={toggleOn}>
        <FormattedMessage {...messages.discard} />
      </Button>
    </>
  );
};
