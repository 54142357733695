import { useToggle } from '@cooltra/hooks';
import { FormattedMessage } from 'react-intl';
import { useField } from '@cooltra/form';
import { Button, Image } from '@cooltra/ui';
import {
  useDamageImageMutation,
  useDamageImage,
} from '@cooltra/station-based-api';

import { CameraModal } from '../../../CameraModal/CameraModal';

import messages from './messages';

export const CameraImageField = () => {
  const [isModalOpen, { toggleOn, toggleOff }] = useToggle();

  const { value, setValue } = useField('imageId');
  const fetchedImageData = useDamageImage(value);

  const imageData = fetchedImageData
    ? `data:image/jpeg;base64,${fetchedImageData}`
    : '';

  const mutation = useDamageImageMutation();

  if (value) {
    return (
      <div>
        <div className="h-80 bg-neutral-100 rounded-lg overflow-hidden">
          <Image
            key={value}
            src={imageData}
            className="h-full w-full object-cover"
          />
        </div>
        <CameraModal
          onSave={mutation.mutateAsync}
          isOpen={isModalOpen}
          onRequestClose={toggleOff}
          onChange={setValue}
          fileType={'zeus-damage-image'}
        />
        <Button emphasis="low" size="sm" onClick={toggleOn} className="mt-2">
          <FormattedMessage {...messages.changePicture} />
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full h-80 bg-neutral-100 rounded-lg">
      <Button onClick={toggleOn}>
        <FormattedMessage {...messages.takePicture} />
      </Button>
      <CameraModal
        onSave={mutation.mutateAsync}
        isOpen={isModalOpen}
        onRequestClose={toggleOff}
        onChange={setValue}
        fileType={'zeus-damage-image'}
      />
    </div>
  );
};
