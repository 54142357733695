import { ReservationState } from '@cooltra/api';
import { Icon, Badge, BadgeVariant } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { MdCheck, MdClose } from 'react-icons/md';

import { getReservationStateMessage } from '~/utils/rentals';

type ReservationStateBadgeProps = {
  state: ReservationState;
};

const mapStateToBadge: { [key in ReservationState]: BadgeVariant } = {
  FAILED: 'danger',
  CANCELED: 'danger',
  EXPIRED: 'danger',
  USED: 'primary',
  ACTIVE: 'success',
  CHECKING_IN: 'neutral',
};

const mapStateToIcon: { [key in ReservationState]: JSX.Element } = {
  FAILED: <MdClose />,
  CANCELED: <MdClose />,
  EXPIRED: <MdClose />,
  CHECKING_IN: (
    <span className="w-3 h-3 inline-flex justify-center items-center">
      <span className="inline-block w-1 h-1 rounded-full bg-neutral-600" />
    </span>
  ),
  USED: (
    <span className="w-3 h-3 inline-flex justify-center items-center">
      <span className="inline-block w-1 h-1 rounded-full bg-neutral-600" />
    </span>
  ),
  ACTIVE: <MdCheck />,
};

export const ReservationStateBadge = ({
  state,
}: ReservationStateBadgeProps) => (
  <Badge variant={mapStateToBadge[state]}>
    <div className="flex items-center gap-1">
      <Icon>{mapStateToIcon[state]}</Icon>
      <span className="block">
        <FormattedMessage {...getReservationStateMessage(state)} />
      </span>
    </div>
  </Badge>
);
