import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';

import { usePreferredServicePoints } from '~/libs/preferred-service-points';

import messages from './messages';
import { filterInitialFormValues } from './FilterFormProvider';

export const ClearFiltersButton = () => {
  const { resetForm } = useFormContext();
  const { preferredServicePoints: savedServicePointIds } =
    usePreferredServicePoints();
  const clearFilters = () =>
    resetForm({
      ...filterInitialFormValues,
      servicePointId: savedServicePointIds,
    });

  return (
    <button type="button" className="text-primary-700" onClick={clearFilters}>
      <FormattedMessage {...messages.clearFilters} />
    </button>
  );
};
