import { FormattedMessage } from 'react-intl';
import { Vehicle } from '@cooltra/api';

import { StaticField } from '~/common';

import messages from './messages';

export type QrIdStaticProps = {
  value: Vehicle['qrId'];
};

export const QrIdStatic = ({ value }: QrIdStaticProps) => (
  <StaticField
    renderLabel={<FormattedMessage {...messages.qrId} />}
    renderValue={value}
  />
);
