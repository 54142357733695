import { differenceInHours } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Contract } from '@cooltra/station-based-api';

import { ContractOverviewDates } from '~/common';

import messages from './messages';

export type DetailsProps = Pick<Contract, 'details'>;

export const Details = ({ details }: DetailsProps) =>
  details ? (
    <>
      <span className="block text-sm text-neutral-800 mb-4">
        <FormattedMessage
          {...messages.days}
          values={{
            totalDays: Math.ceil(
              differenceInHours(
                new Date(`${details.endDate}T${details.endTime}`),
                new Date(`${details.startDate}T${details.startTime}`)
              ) / 24
            ),
          }}
        />
      </span>
      <ContractOverviewDates details={details} />
    </>
  ) : null;
