import { TicketPaymentMethod } from '@cooltra/station-based-api';
import { defineMessages } from 'react-intl';

export default defineMessages<TicketPaymentMethod>({
  TPV: {
    defaultMessage: 'TPV La Caixa',
  },
  STRIPE_QR: {
    defaultMessage: 'Charge ticket with Stripe QR',
  },
  CASH: {
    defaultMessage: 'Cash',
  },
  CARD: {
    defaultMessage: 'Stripe card',
  },
  EMAIL: {
    defaultMessage: 'Send payment link by email',
  },
});
