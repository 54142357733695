import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { ScrollToTop } from '@cooltra/ui';

import { Four0Three } from '~/common';

import { VehicleTimeline } from '../VehicleTimeline';
import { TelematicsInformation } from '../TelematicsInformation';
import { CurrentActivity } from '../CurrentActivity';
import { OpenVehicleProblemReports } from '../OpenVehicleProblemReports';
import { OpenVehicleTasks } from '../OpenVehicleTasks';
import { VehicleBonus } from '../VehicleBonus';
import { CurrentPositionProvider } from '../CurrentPositionContext';

export type VehicleOverviewProps = {
  vehicleId: string;
};

export const VehicleOverview = ({ vehicleId }: VehicleOverviewProps) => {
  const { hasPermission } = useAuthClaimsQuery();

  if (!hasPermission('read:vehicles')) {
    return <Four0Three />;
  }

  return (
    <CurrentPositionProvider>
      <div
        className="container py-12 px-10 min-w-5xl max-w-7xl"
        data-testid="VEHICLE_OVERVIEW"
      >
        <ScrollToTop />
        <div className="w-full gap-10 flex">
          <div className="flex flex-col flex-1 gap-10">
            <OpenVehicleTasks vehicleId={vehicleId} />
            <OpenVehicleProblemReports vehicleId={vehicleId} />
            <VehicleTimeline vehicleId={vehicleId} />
          </div>
          <div className="w-96 flex flex-col gap-10">
            <section data-testid="VEHICLE_CURRENT_ACTIVITY">
              <CurrentActivity vehicleId={vehicleId} />
            </section>
            <section data-testid="TELEMATICS_INFORMATION">
              <TelematicsInformation vehicleId={vehicleId} />
            </section>
            <section>
              <VehicleBonus vehicleId={vehicleId} />
            </section>
          </div>
        </div>
      </div>
    </CurrentPositionProvider>
  );
};
