import { Legend } from '@cooltra/ui';
import { MdLocationOn, MdOutlineLaunch } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormRadioButtonGroupField, useFormContext } from '@cooltra/form';

import { CardLayout } from '../CardLayout/CardLayout';
import { AddressFields } from '../../../AddressFields';
import { ReviewFormValues } from '../../UserReviewForm/review-form-types';

import messages from './messages';

export const AddressCheck = () => {
  const { formatMessage } = useIntl();
  const { values } = useFormContext<ReviewFormValues>();
  const googleSearchQuery = `${values.street} ${values.zip} ${values.city} ${values.country}`;

  return (
    <CardLayout
      data-testid="ADDRESS_CHECK"
      icon={<MdLocationOn />}
      heading={
        <div className="flex justify-between items-center">
          <FormattedMessage {...messages.heading} />
          <a
            className="flex items-center gap-1 text-sm font-normal text-primary-400"
            href={`http://maps.google.com/?q=${googleSearchQuery}`}
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage {...messages.seeOnGoogleMaps} />
            <MdOutlineLaunch />
          </a>
        </div>
      }
    >
      <div className="grid gap-5">
        <AddressFields.Street />
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <AddressFields.Zip />
          </div>
          <div className="col-span-2">
            <AddressFields.City />
          </div>
        </div>
        <AddressFields.Country />
        <FormRadioButtonGroupField
          legend={
            <Legend
              tooltip={
                <div className="max-w-sm">
                  <p className="text-sm leading-relaxed">
                    <FormattedMessage {...messages.tooltipBody} />
                  </p>
                </div>
              }
            >
              <FormattedMessage {...messages.isAddressComplete} />
            </Legend>
          }
          name="isAddressComplete"
          items={[
            {
              value: false,
              variant: 'invalid',
              label: formatMessage(messages.incomplete),
              id: 'address-incomplete',
            },
            {
              value: true,
              variant: 'valid',
              label: formatMessage(messages.complete),
              id: 'address-complete',
            },
          ]}
        />
      </div>
    </CardLayout>
  );
};
