import { ContractType } from '@cooltra/station-based-api';
import { getKeys } from '@cooltra/utils';

const contractTypeNames: Record<ContractType, string> = {
  WALK_BY: 'Walk-by',
  RIDERLY: 'Riderly',
  BIKES_BOOKING: 'BikesBooking',
  MANUAL_RESERVATION: 'Manual reservation',
  APP: 'App',
  WEB: 'Web',
};

export const allContractTypes = getKeys(contractTypeNames);

export const getContractTypeName = (type: string | null) => {
  if (!type) {
    return '';
  }

  if (
    allContractTypes
      .map((contractType) => contractType.toLowerCase())
      .includes(type.toLowerCase())
  ) {
    return contractTypeNames[type as ContractType];
  }

  return type;
};
