import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    defaultMessage: 'Add',
  },
  perMonth: {
    defaultMessage: 'per month',
  },
});
