import { Image } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { useFetchDocument } from '@cooltra/api';
import { useState, ImgHTMLAttributes } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { useRotation } from '~/hooks';

import { photoSize, PhotoSize } from '../photo-classes';
import '../photo.css';

import { Controls } from './Controls';

export type UserPhotoWithPanPinchProps = ImgHTMLAttributes<HTMLImageElement> & {
  initialSize?: PhotoSize;
  className?: string;
  imageKey: string;
  onClick?: () => void;
};

const hasChangedAxis = (rotation: number) =>
  rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;

const getPhotoClasses = (rotation: number, size: PhotoSize) => {
  const constrainedDimension = hasChangedAxis(rotation) ? 'width' : 'height';

  return classNames(
    'object-contain',
    photoSize[size][constrainedDimension],
    constrainedDimension === 'height' ? 'w-auto' : 'h-auto'
  );
};

export const UserPhotoWithPanPinch = ({
  initialSize = 'md',
  imageKey,
  className,
  onClick,
  ...rest
}: UserPhotoWithPanPinchProps) => {
  const imageData = useFetchDocument(imageKey);

  const { rotation, rotationClassName, rotateLeft, rotateRight } =
    useRotation();

  const [size, setSize] = useState(initialSize);

  const expandImage = () => {
    if (size === 'md') {
      setSize('lg');
    }
    if (size === 'lg') {
      setSize('md');
    }
    onClick && onClick();
  };

  const imageDataWithBase64 = `data:image/jpeg;base64,${imageData}`;
  // Related to CSS Rule: user-photo-h-lg
  const userPhotoHeightStyle = '30.5rem';
  const style = {
    height: userPhotoHeightStyle,
    overflow: 'visible',
  };

  return (
    <div className={classNames(photoSize[size].height, className)}>
      <div
        tabIndex={0}
        className="group relative bg-neutral-100 overflow-hidden rounded-md outline-none h-full"
      >
        <div
          className={classNames(
            'h-full flex items-center justify-center',
            size === 'lg' && 'cursor-grab'
          )}
        >
          <TransformWrapper initialScale={1} disabled={size === 'md'}>
            {() => (
              <div>
                <Controls
                  expandImage={expandImage}
                  rotateLeft={rotateLeft}
                  rotateRight={rotateRight}
                />
                <TransformComponent wrapperStyle={style} contentStyle={style}>
                  <Image
                    src={imageDataWithBase64}
                    className={classNames(
                      getPhotoClasses(rotation, size),
                      rotationClassName
                    )}
                    {...rest}
                  />
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </div>
    </div>
  );
};
