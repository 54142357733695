import { Contract } from '@cooltra/station-based-api';

import { ContractDriverForm, ContractPendingSignAlert } from '~/common';
import { useContractFlags } from '~/hooks';
import { getEnvVariable } from '~/utils/environment';

import { ContractDriverDetailsNavigationTabs } from './ContractDriverDetailsNavigationTabs/ContractDriverDetailsNavigationTabs';
import { SendCheckInEmail } from './SendCheckInEmail/SendCheckInEmail';

export type ContractDriverDetailsProps = {
  contract: Contract;
};

export const ContractDriverDetails = ({
  contract,
}: ContractDriverDetailsProps) => {
  const { isTaxIdRequired, isSignedOrPendingSignature } = useContractFlags();
  const { contractId } = contract;

  return (
    <div>
      <div className="mx-auto max-w-4xl pt-4 pb-10">
        <ContractPendingSignAlert />
        <div className="flex justify-between gap-4">
          <ContractDriverDetailsNavigationTabs contract={contract} />
          {getEnvVariable('ENVIRONMENT') !== 'production' && (
            <SendCheckInEmail contractId={contractId} />
          )}
        </div>
        <ContractDriverForm
          contract={contract}
          disabled={isSignedOrPendingSignature}
          isTaxIdRequired={isTaxIdRequired}
        />
      </div>
    </div>
  );
};
