import { defineMessages } from 'react-intl';

export default defineMessages({
  monthlyPrice: {
    defaultMessage: 'Per month',
  },
  assignLicensePlate: {
    defaultMessage: 'Assign license plate',
  },
  changeLicensePlate: {
    defaultMessage: 'Change license plate',
  },
  orSimilar: {
    defaultMessage: '{vehicleTypeName} or similar',
  },
});
