import { defineMessages } from 'react-intl';

export default defineMessages({
  vehicleInformation: {
    defaultMessage: 'Vehicle information',
  },
  sharedWithCityHall: {
    defaultMessage: 'Shared with city hall',
  },
  overview: {
    defaultMessage: 'Overview',
  },
  services: {
    defaultMessage: 'Services',
  },
  tasks: {
    defaultMessage: 'Tasks',
  },
  rentals: {
    defaultMessage: 'Rentals',
  },
  reservations: {
    defaultMessage: 'Reservations',
  },
  reports: {
    defaultMessage: 'Reports',
  },
  vehicleIdentificationNumber: {
    defaultMessage: 'VIN:',
  },
  telematics: {
    defaultMessage: 'Telematics:',
  },
  model: {
    defaultMessage: 'Model',
  },
  nextService: {
    defaultMessage: 'Next service:',
  },
  nextInspection: {
    defaultMessage: 'Next inspection:',
  },
  viewOn: {
    defaultMessage: 'View on',
  },
  opsApp: {
    defaultMessage: 'Ops App',
  },
});
