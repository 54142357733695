import { defineMessages } from 'react-intl';

export default defineMessages<number>({
  1: {
    defaultMessage: '1 month',
  },
  6: {
    defaultMessage: '6 months',
  },
  12: {
    defaultMessage: '12 months',
  },
});
