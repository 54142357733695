import { defineMessages } from 'react-intl';

export default defineMessages({
  return: {
    defaultMessage: 'Return',
  },
  servicePoint: {
    defaultMessage: 'Service point',
  },
});
