import { Contract } from '@cooltra/station-based-api';

import { ContractDriverForm } from '~/common';
import { useSubscriptionContractFlags } from '~/hooks';

export type SubscriptionContractDriverProps = {
  contract: Contract;
};

export const SubscriptionContractDriver = ({
  contract,
}: SubscriptionContractDriverProps) => {
  const { isTaxIdRequired } = useSubscriptionContractFlags();

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10">
      <ContractDriverForm
        contract={contract}
        isTaxIdRequired={isTaxIdRequired}
      />
    </div>
  );
};
