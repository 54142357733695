import { FormattedMessage } from 'react-intl';
import { FormAutocompleteField, useField } from '@cooltra/form';
import { Card } from '@cooltra/ui';

import { allContractTypes, getContractTypeName } from '~/utils/contract';

import messages from './messages';

export type ContractChannelProps = {
  disabled?: boolean;
};

const name = 'type';

export const ContractChannel = ({ disabled = false }: ContractChannelProps) => {
  const { setValue } = useField(name);

  const handleSelectedItemChange = ({
    selectedItem,
  }: {
    selectedItem?: string | null | undefined;
  }) => selectedItem && setValue(selectedItem);

  const contractTypePresetValues = allContractTypes
    .filter((contractType) => contractType !== 'WEB' && contractType !== 'APP')
    .map((contractType) => getContractTypeName(contractType));

  return (
    <div>
      <h2 className="text-xl text-neutral-700 mb-4">
        <FormattedMessage {...messages.entryChannel} />
      </h2>
      <Card className="pt-8 pb-14 px-14">
        <div className="max-w-xs text-sm text-neutral-1000">
          <FormAutocompleteField
            name={name}
            disabled={disabled}
            onSelectedItemChange={handleSelectedItemChange}
            items={contractTypePresetValues}
            label={<FormattedMessage {...messages.channel} />}
          />
        </div>
      </Card>
    </div>
  );
};
