import { InputLabel } from '@cooltra/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormMultiSelectField } from '@cooltra/form';
import { ReservationState } from '@cooltra/api';

import { getReservationStateMessage } from '~/utils/rentals';

import messages from './messages';

const states: [
  ReservationState,
  'danger' | 'primary' | 'success' | 'neutral',
][] = [
  ['ACTIVE', 'success'],
  ['USED', 'primary'],
  ['FAILED', 'danger'],
  ['CANCELED', 'danger'],
  ['EXPIRED', 'danger'],
  ['CHECKING_IN', 'neutral'],
];

export const State = () => {
  const { formatMessage } = useIntl();
  return (
    <FormMultiSelectField
      name="state"
      id="state"
      isClearable
      placeholder={formatMessage(messages.placeholder)}
      label={
        <InputLabel htmlFor="state">
          <FormattedMessage {...messages.state} />
        </InputLabel>
      }
      options={states.map(([value, variant]) => ({
        value,
        variant,
        label: formatMessage(getReservationStateMessage(value)),
      }))}
    />
  );
};
