import { FormattedMessage } from 'react-intl';
import { useVehicleDamagesQuery } from '@cooltra/station-based-api';
import { SpinnerScreen } from '@cooltra/ui';

import { EmptyListMessageCard, ListErrorMessageCard } from '../../../List';
import { AddDamage } from '../AddDamage/AddDamage';
import { DamageCard } from '../DamageCard/DamageCard';

import messages from './messages';

export type DamagesProps = {
  vehicleId?: string;
  disabled?: boolean;
};

export const Damages = ({ vehicleId = '', disabled = false }: DamagesProps) => {
  const {
    data: damages,
    isLoading,
    isError,
  } = useVehicleDamagesQuery(vehicleId, {
    enabled: !!vehicleId,
  });

  if (!vehicleId) {
    return null;
  }

  if (isLoading) {
    return <SpinnerScreen />;
  }

  return (
    <div className="mx-auto max-w-4xl pt-4 pb-10" data-testid="DAMAGES">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-xl text-neutral-700">
          <FormattedMessage {...messages.damages} />
        </h1>
        <AddDamage vehicleId={vehicleId} disabled={disabled} />
      </div>
      <div>
        {isError ? (
          <ListErrorMessageCard>
            <FormattedMessage {...messages.error} />
          </ListErrorMessageCard>
        ) : damages?.length ? (
          <ul className="grid grid-cols-2 gap-4">
            {damages.map(
              ({ damageId, imageId, type, vehiclePart, createdAt }) => (
                <li key={damageId}>
                  <DamageCard
                    vehicleId={vehicleId}
                    damageId={damageId}
                    imageId={imageId}
                    type={type}
                    vehiclePart={vehiclePart}
                    createdAt={createdAt}
                    disabled={disabled}
                  />
                </li>
              )
            )}
          </ul>
        ) : (
          <EmptyListMessageCard>
            <FormattedMessage {...messages.empty} />
          </EmptyListMessageCard>
        )}
      </div>
    </div>
  );
};
