import { Form } from '@cooltra/form';
import { Card, List } from '@cooltra/ui';
import { getErrorStatus } from '@cooltra/axios';
import {
  RentalsSort,
  useWorkShiftsQuery,
  WorkShiftListItem,
} from '@cooltra/api';

import {
  ApplyFiltersButton,
  ErrorPage,
  FilterFields,
  Four0Three,
  NoSearchResults,
  ResetFiltersButton,
} from '~/common';
import { yesterday } from '~/utils/date';
import { useNavigateWithQueryParams } from '~/hooks';

import { ShiftsTotal } from './ShiftsTotal/ShiftsTotal';
import { ShiftCard, ShiftCardLoading } from './ShiftCard';
import * as ShiftsFilterFields from './ShiftsFilterFields';
import { useShiftsFiltersFromUrl } from './ShiftsFiltersForm';
import { shiftsFiltersInitialValues } from './shifts-filters-form';
import { ShiftsListHeader } from './ShiftsListHeader/ShiftsListHeader';

const initialFilterValues = {
  ...shiftsFiltersInitialValues,
  minDate: yesterday,
};

export const ShiftsList = () => {
  const navigateWithQueryParams = useNavigateWithQueryParams();
  const shiftsFiltersFromUrl = useShiftsFiltersFromUrl();
  const { data, isLoading, isError, error } =
    useWorkShiftsQuery(shiftsFiltersFromUrl);

  const applySortFilter = (sort: string) =>
    navigateWithQueryParams({
      ...shiftsFiltersFromUrl,
      sort: sort as RentalsSort,
    });

  const resetFilters = () => navigateWithQueryParams(initialFilterValues);

  const shiftsTotal = data?.workShifts.length || 0;

  if (isError && getErrorStatus(error) === 403) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div
      data-testid="SHIFTS_LIST"
      className="container min-w-3xl max-w-6xl py-12"
    >
      <Form>
        <Card className="p-8 mb-10" data-testid="SHIFTS_FILTERS">
          <div className="grid grid-cols-3 gap-y-6 gap-x-8 mb-12">
            <FilterFields.System />
            <ShiftsFilterFields.Operator />
            <FilterFields.Created />
          </div>
          <div className="flex justify-between">
            <ResetFiltersButton
              onClick={resetFilters}
              initialValues={initialFilterValues}
            />
            <ApplyFiltersButton />
          </div>
        </Card>
        <div className="flex items-center justify-between mb-12">
          <ShiftsTotal loading={isLoading} total={shiftsTotal} />
          <ShiftsFilterFields.Sort onChange={applySortFilter} />
        </div>
      </Form>
      <List
        isLoading={isLoading}
        loadingRows={4}
        idProp="workShiftId"
        data={data?.workShifts || []}
        renderEmptyMessage={<NoSearchResults />}
        renderHeader={<ShiftsListHeader />}
        renderRow={(shift: WorkShiftListItem) => <ShiftCard shift={shift} />}
        renderLoadingRow={<ShiftCardLoading />}
      />
    </div>
  );
};
