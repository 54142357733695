import {
  Form,
  FormButton,
  FormProvider,
  FormRadioGroupCustomField,
} from '@cooltra/form';
import { useToggle } from '@cooltra/hooks';
import { Modal } from '@cooltra/ui';
import { MdAdd, MdEvent, MdSync } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { classNames } from '@cooltra/utils';
import { useContractMutation } from '@cooltra/station-based-api';
import { useNavigate } from 'react-router-dom';

import { useNotification } from '~/hooks';

import messages from './messages';

type ContractType = 'short-term' | 'subscription';

type ContractTypeOption = {
  label: string;
  value: ContractType;
};

const optionIcons: Record<ContractType, JSX.Element> = {
  'short-term': <MdEvent className="text-2xl" />,
  subscription: <MdSync className="text-2xl" />,
};

export const NewContractType = () => {
  const { formatMessage } = useIntl();
  const [isOpen, { toggleOff, toggleOn }] = useToggle();
  const navigate = useNavigate();
  const { addErrorNotification } = useNotification();

  const { mutateAsync } = useContractMutation();

  const handleSubmit = (values: { contractType: string }) =>
    mutateAsync({
      isSubscription: values.contractType === 'subscription',
    })
      .then((data) =>
        navigate(`/contracts/${values.contractType}/${data.contractId}/details`)
      )
      .catch(() => addErrorNotification());

  const contractTypeOptions: ContractTypeOption[] = [
    {
      label: formatMessage(messages.shortTerm),
      value: 'short-term',
    },
    {
      label: formatMessage(messages.subscription),
      value: 'subscription',
    },
  ];

  return (
    <>
      <button
        className="w-full aspect-square flex items-center text-2xl text-neutral-300"
        onClick={toggleOn}
        aria-label={formatMessage(messages.createContract)}
      >
        <MdAdd />
      </button>
      <Modal isOpen={isOpen} onRequestClose={toggleOff}>
        <FormProvider
          initialValues={{ contractType: '' }}
          onSubmit={handleSubmit}
        >
          <Form className="min-w-xl px-12 pb-12 flex flex-col gap-2 items-center">
            <div className="w-full">
              <FormRadioGroupCustomField
                id="contract-type"
                name="contractType"
                legend={
                  <label
                    htmlFor="contract-type"
                    className="block text-xl font-semibold text-neutral-700 mb-8 text-center"
                  >
                    <FormattedMessage {...messages.newContract} />
                  </label>
                }
                items={contractTypeOptions}
                renderRadio={({ label, checked, value }) => (
                  <div
                    className={classNames(
                      'p-6 flex gap-2 w-full font-semibold transition-colors',
                      checked ? 'text-primary-500' : 'text-neutral-600'
                    )}
                  >
                    {optionIcons[value as ContractType]}
                    <span>{label}</span>
                  </div>
                )}
              />
            </div>
            <FormButton disablePristine className="mt-9">
              <FormattedMessage {...messages.createNew} />
            </FormButton>
          </Form>
        </FormProvider>
      </Modal>
    </>
  );
};
