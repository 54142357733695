import { Divider, List } from '@cooltra/ui';
import { useLocation } from 'react-router-dom';
import { useFormContext } from '@cooltra/form';
import { getErrorStatus } from '@cooltra/axios';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getParams, stringifyParams } from '@cooltra/utils';
import { AlarmListItem, AlarmType, useAlarmsQuery } from '@cooltra/api';

import {
  AlarmsColumnLabel,
  ErrorPage,
  Four0Three,
  NoSearchResults,
  SortFields,
} from '~/common';

import { AlarmCard, AlarmCardLoading } from '../AlarmCard';
import { AlarmRowLayout } from '../AlarmRowLayout/AlarmRowLayout';
import { AlarmsListHeader } from '../AlarmsListHeader/AlarmsListHeader';
import { AlarmTypeFilterLink } from '../AlarmTypeFilterLink/AlarmTypeFilterLink';

import { AlarmsFiltersFormValues } from './alarms-filters-form';

export const AlarmsList = () => {
  const { search } = useLocation();
  const params = getParams<'type'>(search);
  const typeParam = params.string('type') as AlarmType | undefined;
  const { data: claims, hasPermission } = useAuthClaimsQuery();
  const { values } = useFormContext<AlarmsFiltersFormValues>();
  const { isLoading, isError, error, data } = useAlarmsQuery({
    system: claims?.systems || [],
  });
  const operatorWithSystemWithA300 = claims?.systems.includes('Eindhoven');
  const alarmTypes: AlarmType[] = operatorWithSystemWithA300
    ? ['UNLOCKED', 'OFFLINE', 'STOLEN', 'BATTERY_UNLOCKED']
    : ['UNLOCKED', 'OFFLINE', 'STOLEN'];

  const filterByType = (type: AlarmType | undefined) => {
    if (!data) {
      return [];
    }

    if (!type) {
      return data.alarms;
    }

    return data.alarms.filter((alarm) => alarm.types.includes(type));
  };

  if (
    (isError && getErrorStatus(error) === 403) ||
    !hasPermission('read:vehicles')
  ) {
    return <Four0Three />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  const sortItems = (items: AlarmListItem[]) => {
    return items.sort((alarm, nextAlarm) => {
      if (values.sort === 'updatedAt.desc') {
        return new Date(alarm.createdAt) > new Date(nextAlarm.createdAt)
          ? -1
          : 1;
      }

      return new Date(alarm.createdAt) > new Date(nextAlarm.createdAt) ? 1 : -1;
    });
  };

  return (
    <div className="container min-w-3xl max-w-6xl py-12">
      <AlarmsListHeader />
      <div className="flex gap-1.5 mb-10">
        <AlarmTypeFilterLink
          isLoading={isLoading}
          isActive={typeParam === undefined}
          to=""
          count={data?.alarms.length || 0}
        />
        {alarmTypes.map((type) => (
          <AlarmTypeFilterLink
            key={type}
            isLoading={isLoading}
            isActive={typeParam === type}
            to={`?${stringifyParams({ type })}`}
            type={type}
            count={filterByType(type).length}
          />
        ))}
      </div>
      <Divider className="mb-10" />
      <div className="flex justify-end items-center mb-12">
        <SortFields.UpdatedAt />
      </div>
      <div data-testid="ALARMS_LIST">
        <List
          key={typeParam}
          loadingRows={4}
          isLoading={isLoading}
          idProp="createdAt"
          data={sortItems(filterByType(typeParam))}
          renderHeader={
            <AlarmRowLayout
              vehicle={<AlarmsColumnLabel name="vehicle" />}
              alarms={<AlarmsColumnLabel name="alarm" />}
              duration={<AlarmsColumnLabel name="startedAt" />}
            />
          }
          renderEmptyMessage={<NoSearchResults />}
          renderLoadingRow={<AlarmCardLoading />}
          renderRow={(alarm: AlarmListItem) => <AlarmCard alarm={alarm} />}
        />
      </div>
    </div>
  );
};
