import { Contract } from '@cooltra/station-based-api';

export const areChecksOutCompleted = (contract: Contract, isBike: boolean) =>
  contract.checks &&
  contract.checks.out &&
  contract.checks.out.mileage &&
  (isBike || contract.checks.out.fuelLevel);

export const areChecksInCompleted = (contract: Contract, isBike: boolean) =>
  contract.checks &&
  contract.checks.in &&
  contract.checks.in.mileage &&
  (isBike || contract.checks.in.fuelLevel);
