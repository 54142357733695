import { ContractDetails } from '@cooltra/station-based-api';
import { MdArrowDownward } from 'react-icons/md';
import { useIntl } from 'react-intl';

export type ConractOverviewDatesProps = {
  details: ContractDetails;
};

export const ContractOverviewDates = ({
  details,
}: ConractOverviewDatesProps) => {
  const { formatDate } = useIntl();

  const formatDateTime = (date: string, time: string) =>
    `${formatDate(date, {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })} - ${time}`;

  return (
    <div>
      <div className="flex justify-between mb-2 gap-8">
        <div>
          <span className="block text-sm text-neutral-600 mb-2">
            {details.servicePointName}
          </span>
          <span className="text-base text-neutral-800 font-medium">
            {formatDateTime(details.startDate, details.startTime)}
          </span>
        </div>
        {details.endDate && details.endTime && (
          <>
            <MdArrowDownward className="text-lg mt-4 -rotate-90 text-neutral-800" />
            <div>
              <span className="block text-sm text-neutral-600 mb-2">
                {details.servicePointName}
              </span>
              <span className="text-base font-medium text-neutral-800">
                {formatDateTime(details.endDate, details.endTime)}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
