import { Button, Modal } from '@cooltra/ui';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@cooltra/form';
import { ContractStatus } from '@cooltra/station-based-api';

import { Calendar } from '../Calendar';
import { CalendarAlert } from '../CalendarAlert';
import { ClearDates } from '../ClearDates/ClearDates';
import { PickupTime } from '../PickupTime/PickupTime';
import { ReturnTime } from '../ReturnTime/ReturnTime';
import { exceedsMaxRentalDuration } from '../utils';
import { DateTimePickerFormValues } from '../types';

import messages from './messages';

export type DateTimePickerProps = {
  contractStatus: ContractStatus;
  isOpen: boolean;
  closeModal: () => void;
};

export const DateTimePicker = ({
  isOpen,
  closeModal,
  contractStatus,
}: DateTimePickerProps) => {
  const {
    setTouched,
    values: { pickUpDate, dropOffDate, pickUpTime, dropOffTime },
  } = useFormContext<DateTimePickerFormValues>();

  const exceedsMaxDuration = !exceedsMaxRentalDuration(pickUpDate, dropOffDate);

  const isDisabled = !pickUpDate || !dropOffDate || !pickUpTime || !dropOffTime;

  const handleClosingModal = () => {
    closeModal();
    setTouched({
      pickUpDate: true,
      pickUpTime: true,
      dropOffDate: true,
      dropOffTime: true,
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClosingModal}>
      <div
        className="bg-neutral-0 px-12 pt-2 pb-5 min-w-3xl"
        data-testid="DATE_TIME_PICKER"
      >
        <div className="mb-6 flex w-full items-center justify-between">
          <span className="block text-xl font-semibold text-neutral-800">
            <FormattedMessage {...messages.selectDates} />
          </span>
          <ClearDates contractStatus={contractStatus} />
        </div>
        <Calendar contractStatus={contractStatus} />
      </div>
      <div className="px-12 pb-12">
        <div className="flex gap-4 items-end justify-between min-h-20">
          <div className="flex items-end gap-4">
            <PickupTime contractStatus={contractStatus} />
            <ReturnTime />
          </div>
          {exceedsMaxDuration && (
            <CalendarAlert variant="danger">
              <FormattedMessage {...messages.maxRentalAlert} />
            </CalendarAlert>
          )}
          <Button
            variant="primary"
            emphasis="high"
            disabled={isDisabled || exceedsMaxDuration}
            onClick={handleClosingModal}
          >
            <FormattedMessage {...messages.selectDates} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
